import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { EventPhotoResultInterface } from "../interfaces/event-photo-result.interface";
import { EventPhotoSingleResultInterface } from "../interfaces/event-photo-single-result.interface";

export interface EventPhotoParam {
  size: number;
  page: number;
  event_id: string;
}

export const GetEventPhotos = async (param: EventPhotoParam): Promise<EventPhotoResultInterface> => {
  try {
    const result = await ApiDisaster.get("/event-photos", {
      params: {
        size: param.size,
        page: param.page,
        event_id: param.event_id
      }
    });
    return result.data as EventPhotoResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowEventPhoto = async (id: string): Promise<EventPhotoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.get(
      '/event-photos/' + id,
    );
    return response.data as EventPhotoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventPhotoSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitEventPhoto = async (form: any): Promise<EventPhotoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.post(
      '/event-photos',
      form
    );
    return response.data as EventPhotoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventPhotoSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateEventPhoto = async (id: string, form: any): Promise<EventPhotoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.put(
      '/event-photos/' + id,
      form
    );
    return response.data as EventPhotoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventPhotoSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteEventPhoto = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.delete(
      '/event-photos/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}