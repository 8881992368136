import { AxiosError } from "axios";
import { Api, ApiAuthorization } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { UserSingleResultInterface } from "../../users/interfaces/user-single-result.interface";
import { SuccessLoginInterface } from "../interfaces/success-login.interface";

export const Login = async (
  username: string,
  password: string
): Promise<SuccessLoginInterface> => {
  try {
    const doLogin = await Api.post("/login-admin", {
      username: username,
      password: password,
    });
    return doLogin.data as SuccessLoginInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const GetMe = async (): Promise<UserSingleResultInterface> => {
  try {
    const doLogin = await ApiAuthorization.get("/me");
    return doLogin.data as UserSingleResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};
