import create from "zustand";
import { toast } from "react-toastify";
import { EventSingleResultInterface } from "../../disaster/interfaces/event-single-result.interface";
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from "../../forms/interfaces/form-input.interface";
import { generateFormLogic } from "../../../helpers/generate-form-logic";
import { AssignmentSingleResultInterface } from "../interfaces/assignment-single-result.interface";
import {
  AssignmentData,
  AssignmentResultInterface,
} from "../interfaces/assignment-result.interface";
import {
  AssignmentParam,
  GetAssignments,
  DeleteAssignment,
  ShowAssignment,
  SubmitAssignment,
  UpdateAssignment,
} from "../providers/assignment.provider";
import moment from "moment";

interface AssignmentState {
  assignments: AssignmentData[];
  assignmentModel?: AssignmentResultInterface;
  assignmentLoading: boolean;
  assignmentSelectDatas: SelectData[];
  getAssignments: (assignmentParam: AssignmentParam) => void;
  deleteAssignment: (id: string, assignmentParam: AssignmentParam) => void;
}
const useAssignmentStore = create<AssignmentState>((set, get) => ({
  assignments: [],
  assignmentSelectDatas: [],
  assignmentModel: undefined,
  assignmentLoading: false,
  getAssignments: async (assignmentParam: AssignmentParam) => {
    set({ assignmentLoading: true });
    const result = await GetAssignments(assignmentParam);
    set({ assignmentModel: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformsDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformsDatas.push({
              label: item.content,
              value: item.id,
            });
          }
          set({
            assignments: result.data.items,
            assignmentSelectDatas: transformsDatas,
          });
        } else {
          set({
            assignments: [],
            assignmentSelectDatas: [],
          });
        }
      } else {
        set({
          assignments: [],
          assignmentSelectDatas: [],
        });
      }
    } else {
      set({
        assignments: [],
        assignmentSelectDatas: [],
      });
    }
    set({ assignmentLoading: false });
  },
  deleteAssignment: async (id: string, param: AssignmentParam) => {
    set({ assignmentLoading: true });
    const result = await DeleteAssignment(id);
    if (result.status) {
      toast.success(result.message);
      get().getAssignments(param);
    } else {
      toast.error(result.message);
    }
    set({ assignmentLoading: false });
  },
}));

interface AssignmentFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: AssignmentSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const eventFormInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
};

const useAssignmentFormStore = create<AssignmentFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    };
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    };
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowAssignment(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
  },
  generateForms: (formParam: FormProps) => {
    set({ forms: [], formParam: formParam, submitDone: undefined });
    let data: AssignmentData = undefined;
    if (formParam.data !== undefined) {
      data = formParam.data as AssignmentData;
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: "Relawan",
        placeholder: "Relawan...",
        type: "select-with-text",
        name: "user_id",
        selectData: [],
        value: {
          label: data?.user?.fullName,
          value: data?.user?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Pesan",
        placeholder: "Pesan...",
        type: "text",
        name: "content",
        selectData: [],
        value: data?.content ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Tanggal Mulai",
        placeholder: "Tanggal Mulai...",
        type: "date",
        name: "start_date",
        value: moment(data?.start_date).toDate(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Tanggal Akhir",
        placeholder: "Tanggal Akhir...",
        type: "date",
        name: "end_date",
        value: moment(data?.end_date).toDate(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    // check required field
    let errorExist = false;
    for (const v of get().forms) {
      if (v.required === true && v.value !== undefined) {
        toast.error(`Please fill ${v.title}`);
        errorExist = true;
      }
    }

    if (errorExist === true) {
      return;
    }

    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error("Cant submit form, because its read-only");
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ""),
        };
      }

      formData = {
        ...formData,
        is_response: false,
        is_read: false,
        is_life: false,
        event_id: get().formParam?.eventId,
      };

      let res: EventSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE)
        res = await SubmitAssignment(formData);
      if (get().formParam.action === FormAction.UPDATE)
        res = await UpdateAssignment(get().formParam.id, formData);

      if (res.status) {
        toast.success(res.message);
        set({ forms: [], submitDone: true });
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message ?? res.error);
        }
        set({ submitDone: false });
      }
      set({ formLoading: false });
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
    }
  },
  reset: () => {
    set(eventFormInitialState);
  },
}));

export { useAssignmentStore, useAssignmentFormStore };
