import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { ProvinceResultInterface } from "../interfaces/province-result.interface";
import { ProvinceSingleResultInterface } from "../interfaces/province-single-result.interface";

export interface ProvinceParam {
  size: number;
  page: number;
}

export const GetProvinces = async (param: ProvinceParam): Promise<ProvinceResultInterface> => {
  try {
    const result = await ApiDisaster.get("/provinces", {
      params: {
        size: param.size,
        page: param.page
      }
    });
    return result.data as ProvinceResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowProvince = async (id: string): Promise<ProvinceSingleResultInterface> => {
  try {
    let response = await ApiDisaster.get(
      '/provinces/' + id,
    );
    return response.data as ProvinceSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ProvinceSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitProvince = async (form: any): Promise<ProvinceSingleResultInterface> => {
  try {
    let response = await ApiDisaster.post(
      '/provinces',
      form
    );
    return response.data as ProvinceSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ProvinceSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateProvince = async (id: string, form: any): Promise<ProvinceSingleResultInterface> => {
  try {
    let response = await ApiDisaster.put(
      '/provinces/' + id,
      form
    );
    return response.data as ProvinceSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ProvinceSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteProvince = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.delete(
      '/provinces/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}