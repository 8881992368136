import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { CityResultInterface } from "../interfaces/city-result.interface";
import { CitySingleResultInterface } from "../interfaces/city-single-result.interface";

export interface CityParam {
  size: number;
  page: number;
  provCode?: string;
}

export const GetCitys = async (
  param: CityParam
): Promise<CityResultInterface> => {
  try {
    const result = await ApiDisaster.get("/cities", {
      params: {
        size: param.size,
        page: param.page,
        prov_code: param.provCode,
      },
    });
    return result.data as CityResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowCity = async (
  id: string
): Promise<CitySingleResultInterface> => {
  try {
    let response = await ApiDisaster.get("/cities/" + id);
    return response.data as CitySingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: CitySingleResultInterface = a.response?.data;
    return response;
  }
};

export const SubmitCity = async (
  form: any
): Promise<CitySingleResultInterface> => {
  try {
    let response = await ApiDisaster.post("/cities", form);
    return response.data as CitySingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: CitySingleResultInterface = a.response?.data;
    return response;
  }
};

export const UpdateCity = async (
  id: string,
  form: any
): Promise<CitySingleResultInterface> => {
  try {
    let response = await ApiDisaster.put("/cities/" + id, form);
    return response.data as CitySingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: CitySingleResultInterface = a.response?.data;
    return response;
  }
};

export const DeleteCity = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.delete("/cities/" + id);
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};
