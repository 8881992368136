import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from "../../forms/interfaces/form-input.interface";
import {
  UserDetailData,
  UserDetailSingleResultInterface,
} from "../interfaces/user-detail-single-result.interface";
import create from "zustand";
import {
  ShowUserDetail,
  SubmitUserDetail,
  UpdateUserDetail,
} from "../providers/user-detail.provider";
import { toast } from "react-toastify";
import { generateFormLogic } from "../../../helpers/generate-form-logic";
import moment from "moment";
import { GetUserOrganizations } from "../../organizations/providers/user-organization.provider";
import { GetProvinces } from "../../disaster/providers/province.provider";

interface UserDetailFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: UserDetailSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: (userId: string) => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const userDetailFormInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
};

const useUserDetailFormStore = create<UserDetailFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    };
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    };
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowUserDetail(id);
      set({ data: response });
      get().generateForms({
        action: FormAction.UPDATE,
        title: "User Detail",
        subtitle: "Update User Detail",
        id: response.data.id,
        data: response.data,
      });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
  },
  generateForms: async (formParam: FormProps) => {
    set({ forms: [], formParam: formParam, submitDone: undefined });
    let data: UserDetailData = undefined;
    if (formParam.data !== undefined) {
      data = formParam.data as UserDetailData;
    }
    let forms: FormInput[] = [];

    const userOrganizationDatas = await GetUserOrganizations({
      page: 0,
      size: 10000,
    });
    const userOrganizationSelectDatas: SelectData[] = [];
    for (const item of userOrganizationDatas?.data?.items) {
      userOrganizationSelectDatas.push({
        label: item.name,
        value: item.id,
      });
    }

    const provinceDatas = await GetProvinces({ page: 0, size: 100 });
    const provinceSelectDatas: SelectData[] = [];
    for (const item of provinceDatas?.data?.items) {
      provinceSelectDatas.push({
        label: item.name,
        value: item.id,
      });
    }

    forms = [
      {
        title: "Avatar",
        placeholder: "Avatar...",
        type: "file-upload-v2",
        name: "avatar",
        value: data?.avatar ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Nickname",
        placeholder: "Nickname...",
        type: "text",
        name: "nickname",
        value: data?.nickname ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Place Birth",
        placeholder: "Place Birth...",
        type: "text",
        name: "place_birth",
        value: data?.place_birth ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Birthdate",
        placeholder: "Birthdate...",
        type: "date",
        name: "birthdate",
        value: moment(data?.birthdate).toDate(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "User Organization",
        placeholder: "User Organization...",
        type: "select-with-text",
        name: "user_organization_id",
        selectData: userOrganizationSelectDatas,
        value: {
          label: data?.user_organization_id?.name,
          value: data?.user_organization_id?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Gender",
        placeholder: "Gender...",
        type: "select-with-text",
        name: "gender",
        selectData: [
          {
            label: "Male",
            value: "Male",
          },
          {
            label: "Female",
            value: "Female",
          },
        ],
        value: {
          label: data?.gender,
          value: data?.gender,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Address",
        placeholder: "Address...",
        type: "text-area",
        name: "address",
        value: data?.address,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "RT",
        placeholder: "RT...",
        type: "text",
        name: "rt",
        value: data?.rt,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "RW",
        placeholder: "RW...",
        type: "text",
        name: "rw",
        value: data?.rw,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Province",
        placeholder: "Province...",
        type: "select-with-text",
        name: "province_id",
        selectData: provinceSelectDatas,
        value: {
          label: data?.province?.name,
          value: data?.province?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "City",
        placeholder: "City...",
        type: "select-with-text",
        name: "city_id",
        selectData: [],
        value: {
          label: data?.city?.name,
          value: data?.city?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      // {
      //   title: "District",
      //   placeholder: "District...",
      //   type: "select-with-text",
      //   name: "district_id",
      //   selectData: [],
      //   value: {
      //     label: data?.district?.name,
      //     value: data?.district?.id,
      //   },
      //   disabled: formParam.action === FormAction.VIEW ? true : false,
      // },
      // {
      //   title: "Subdistrict",
      //   placeholder: "Subdistrict...",
      //   type: "select-with-text",
      //   name: "subdistrict_id",
      //   selectData: [],
      //   value: {
      //     label: data?.subdistrict?.name,
      //     value: data?.subdistrict?.id,
      //   },
      //   disabled: formParam.action === FormAction.VIEW ? true : false,
      // },
      {
        title: "Postal Code",
        placeholder: "Postal Code...",
        type: "text",
        name: "postal_code",
        value: data?.postal_code,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Is Active",
        placeholder: "Is Active...",
        type: "toggle",
        name: "is_active",
        value: data?.is_active ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Is Duty",
        placeholder: "Is Duty...",
        type: "toggle",
        name: "is_duty",
        value: data?.is_duty ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Is Approved",
        placeholder: "Is Approved...",
        type: "toggle",
        name: "is_approved",
        value: data?.is_approved ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async (userId: string) => {
    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error("Cant submit form, because its read-only");
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ""),
        };
      }

      formData = {
        ...formData,
        user_id: userId,
        description: {
          qualification: "Pelatihan, Geladi dan Simulasi Bencana",
        },
      };

      let res: UserDetailSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE)
        res = await SubmitUserDetail(formData);
      if (get().formParam.action === FormAction.UPDATE)
        res = await UpdateUserDetail(get().formParam.id, formData);

      if (res.status) {
        toast.success(res.message);
        set({ forms: [], submitDone: true });
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message ?? res.error);
        }
        set({ submitDone: false });
      }
      set({ formLoading: false });
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
    }
  },
  reset: () => {
    set(userDetailFormInitialState);
  },
}));

export { useUserDetailFormStore };
