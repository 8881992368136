import Axios, { AxiosInstance } from "axios";
import { NavigateFunction } from "react-router-dom";
import { AuthState } from "../modules/auth/states/auth.state";

// basic api call without token to Terra Auth API
export const Api = Axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

// basic api call with token to Terra Disaster API
export const ApiDisaster = Axios.create({
  baseURL: process.env.REACT_APP_DISASTER_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

// basic api call with token to Terra Assignment API
export const ApiAssignment = Axios.create({
  baseURL: process.env.REACT_APP_ASSIGNMENT_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

// basic api call with token to Terra Resource API
export const ApiResource = Axios.create({
  baseURL: process.env.REACT_APP_RESOURCE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

// basic api call with token to Terra Report API
export const ApiReport = Axios.create({
  baseURL: process.env.REACT_APP_REPORT_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

// basic api call with token to Terra Auth API
export const ApiAuthorization = Axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

export const ApiUploadFile = (): AxiosInstance => {
  let apiKey = process.env?.REACT_APP_STORAGE_KEY;
  return Axios.create({
    baseURL: process.env.REACT_APP_STORAGE_URL!,
    responseType: 'json',
    headers: {
      'Content-Type': 'multipart/form-data',
      'storage-api-key': apiKey,
    }
  });
}

// interceptors apidisaster
export const AxiosDisasterInterceptorsSetup = (navigate: NavigateFunction, useAuthStore: AuthState) => {
  // authStore
  const { logout } = useAuthStore;

  // interceptor for request
  ApiDisaster.interceptors.request.use((config) => {
    // get token from localstorage by item name "token"
    const token = localStorage.getItem("token");

    // add token to header
    config.headers['Authorization'] = `Bearer ${token}`

    return config;
  });

  // interceptor for response
  ApiDisaster.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    console.log(error.response.status)
    if (error.request.status === 401) {
      localStorage.clear();
      logout();
      navigate('/login');
    }
    return Promise.reject(error)
  });
}

// interceptors api assignment
export const AxiosAssignmentInterceptorsSetup = (navigate: NavigateFunction, useAuthStore: AuthState) => {
  // authStore
  const { logout } = useAuthStore;

  // interceptor for request
  ApiAssignment.interceptors.request.use((config) => {
    // get token from localstorage by item name "token"
    const token = localStorage.getItem("token");

    // add token to header
    config.headers['Authorization'] = `Bearer ${token}`

    return config;
  });

  // interceptor for response
  ApiAssignment.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    console.log(error.response.status)
    if (error.request.status === 401) {
      localStorage.clear();
      logout();
      navigate('/login');
    }
    return Promise.reject(error)
  });
}

// interceptors api resource
export const AxiosResourceInterceptorsSetup = (navigate: NavigateFunction, useAuthStore: AuthState) => {
  // authStore
  const { logout } = useAuthStore;

  // interceptor for request
  ApiResource.interceptors.request.use((config) => {
    // get token from localstorage by item name "token"
    const token = localStorage.getItem("token");

    // add token to header
    config.headers['Authorization'] = `Bearer ${token}`

    return config;
  });

  // interceptor for response
  ApiResource.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    console.log(error.response.status)
    if (error.request.status === 401) {
      localStorage.clear();
      logout();
      navigate('/login');
    }
    return Promise.reject(error)
  });
}

// interceptors api report
export const AxiosReportInterceptorsSetup = (navigate: NavigateFunction, useAuthStore: AuthState) => {
  // authStore
  const { logout } = useAuthStore;

  // interceptor for request
  ApiReport.interceptors.request.use((config) => {
    // get token from localstorage by item name "token"
    const token = localStorage.getItem("token");

    // add token to header
    config.headers['Authorization'] = `Bearer ${token}`

    return config;
  });

  // interceptor for response
  ApiReport.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    console.log(error.response.status)
    if (error.request.status === 401) {
      localStorage.clear();
      logout();
      navigate('/login');
    }
    return Promise.reject(error)
  });
}

// interceptors apidisaster
export const AxiosAuthorizationInterceptorsSetup = (navigate: NavigateFunction, useAuthStore: AuthState) => {
  // authStore
  const { logout } = useAuthStore;

  // interceptor for request
  ApiAuthorization.interceptors.request.use((config) => {
    // get token from localstorage by item name "token"
    const token = localStorage.getItem("token");

    // add token to header
    config.headers['Authorization'] = `Bearer ${token}`

    return config;
  });

  // interceptor for response
  ApiAuthorization.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    console.log(error.response.status)
    if (error.request.status === 401) {
      localStorage.clear();
      logout();
      navigate('/login');
    }
    return Promise.reject(error)
  });
}
