import { ReportResultInterface } from "../interfaces/report-result.interface";
import create from "zustand";
import {
  DeleteReport,
  GetReports,
  ReportParam,
  ShowReport,
  SubmitReport,
  UpdateReport,
} from "../providers/report.provider";
import { toast } from "react-toastify";
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from "../../forms/interfaces/form-input.interface";
import {
  ReportData,
  ReportSingleResultInterface,
} from "../interfaces/report-single-result.interface";
import { generateFormLogic } from "../../../helpers/generate-form-logic";

interface ReportState {
  reports: ReportData[];
  reportModel?: ReportResultInterface;
  reportLoading: boolean;
  getReports: (reportParam: ReportParam) => void;
  deleteReport: (id: string, eventId: string) => void;
  selectedReport?: ReportData;
  setSelectedReport?: (data: ReportData) => void;
}

const useReportStore = create<ReportState>((set, get) => ({
  reports: [],
  reportModel: undefined,
  reportLoading: false,
  setSelectedReport: (data: ReportData) => {
    set({ selectedReport: data });
  },
  selectedReport: undefined,
  getReports: async (reportParam: ReportParam) => {
    set({ reportLoading: true });
    const result = await GetReports(reportParam);
    set({ reportModel: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          set({
            reports: result.data.items,
          });
        } else {
          set({
            reports: [],
          });
        }
      } else {
        set({
          reports: [],
        });
      }
    } else {
      set({
        reports: [],
      });
    }
    set({ reportLoading: false });
  },
  deleteReport: async (id: string, eventId: string) => {
    set({ reportLoading: true });
    const result = await DeleteReport(id);
    if (result.status) {
      toast.success(result.message);
      get().getReports({ size: 20, page: 0, event_id: eventId });
    } else {
      toast.error(result.message);
    }
    set({ reportLoading: false });
  },
}));

interface ReportFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: ReportSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: (eventId: string) => void;
  onSubmitFormV2: (eventId: string) => void;
  description: string;
  setDescription: (desc: string) => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const eventFormInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
};

const useReportFormStore = create<ReportFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  description: "",
  setDescription: (desc: string) => {
    set({ description: desc });
  },
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    };
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    };
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowReport(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
  },
  generateForms: (formParam: FormProps) => {
    set({ forms: [], formParam: formParam, submitDone: undefined });
    let data: ReportData = undefined;
    if (formParam.data !== undefined) {
      data = formParam.data as ReportData;
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: "Kronologis / Latar Belakang Kejadian",
        placeholder: "Kronologis / Latar Belakang Kejadian...",
        type: "text-area",
        name: "description",
        value: data?.description ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Korban",
        placeholder: "Korban...",
        type: "text-area",
        name: "fatalities",
        value: data?.fatalities ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Kerugian Materiil",
        placeholder: "Kerugian Materiil...",
        type: "text-area",
        name: "material_loss",
        value: data?.material_loss ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Kondisi Sinyal Telkomsel",
        placeholder: "Kondisi Sinyal Telkomsel...",
        type: "text-area",
        name: "signal_condition",
        value: data?.signal_condition ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Penanganan",
        placeholder: "Penanganan...",
        type: "text-area",
        name: "response_handling",
        value: data?.response_handling ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async (eventId: string) => {
    // check required field
    let errorExist = false;
    for (const v of get().forms) {
      if (v.required === true && (v.value === undefined || v.value === "")) {
        toast.error(`Please fill ${v.title}`);
        errorExist = true;
      }
    }

    if (errorExist === true) {
      return;
    }

    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error("Cant submit form, because its read-only");
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ""),
        };
      }

      let res: ReportSingleResultInterface = {};

      const forms = {
        ...formData,
        event_id: eventId,
      };

      if (get().formParam.action === FormAction.CREATE)
        res = await SubmitReport(forms);
      if (get().formParam.action === FormAction.UPDATE)
        res = await UpdateReport(get().formParam.id, forms);

      if (res.status) {
        toast.success(res.message);
        set({ forms: [], submitDone: true });
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message ?? res.error);
        }
        set({ submitDone: false });
      }
      set({ formLoading: false });
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
    }
  },
  onSubmitFormV2: async (eventId: string) => {
    set({ formLoading: true, submitDone: false });

    const form = {
      description: get().description,
      event_id: eventId,
    };

    const res = await SubmitReport(form);
    if (res.status) {
      toast.success(res.message);
      set({ forms: [], submitDone: true });
    } else {
      if (Array.isArray(res.message)) {
        for (const error of res.message) {
          toast.error(error);
        }
      } else {
        toast.error(res.message ?? res.error);
      }
      set({ submitDone: false });
    }
    set({ formLoading: false });
  },
  reset: () => {
    set(eventFormInitialState);
  },
}));

export { useReportStore, useReportFormStore };
