import { FormInput } from "../interfaces/form-input.interface";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { Form } from "react-bootstrap";

const Input: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  hasInputGroup,
  inputGroupAction,
  inputGroupTitle,
  hidden = false,
  required = false,
}) => {
  return (
    <div className="col-sm-12 col-md-12">
      {
        !hidden && 
        <label className="form-label">
          {title}
          {
            required === true &&
            <span style={{ marginLeft: 5, color: 'red'}}>*</span>
          }
        </label>
      }
      <div className="input-group">
        <input 
          onChange={onChange} 
          placeholder={placeholder} 
          value={value}
          type={type}
          name={name}
          disabled={disabled}
          hidden={hidden}
          className="form-control"/>
          {
            hasInputGroup &&
            <div className="input-group-append">
              <button 
                className="btn btn-secondary" 
                onClick={inputGroupAction}>{inputGroupTitle}</button>
            </div>
          }
        </div>
    </div>  
  );
}

const InputTextArea: React.FC<FormInput> = ({
  title,
  placeholder,
  onChangeTextArea,
  value,
  name,
  disabled = false,
  hidden = false,
  required = false
}) => {
  return (
    <div className="col-sm-12 col-md-12">
      {
        !hidden && 
        <label className="">
          {title}
          {
            required === true &&
            <span style={{ marginLeft: 5, color: 'red'}}>*</span>
          }
        </label>
      }
      <div className="input-group">
        <textarea 
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChangeTextArea}
          className="form-control"
          hidden={hidden}
          disabled={disabled}>
          {value}
        </textarea>
      </div>
    </div>  
  );
}

const DateTimePicker: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  onChangeDateTime,
  onChangeDate,
  required = false,
}) => {
  return (
    <div className="col-sm-12 col-md-12">
      <label>
        {title}
        {
          required === true &&
          <span style={{ marginLeft: 5, color: 'red'}}>*</span>
        }
      </label>
      <input type="hidden" value={value} name={name}/>
      <DatePicker
        dateFormat="yyyy-MM-dd, H:mm"
        name={name}
        className={'form-control'}
        selected={value} 
        disabled={disabled}
        timeInputLabel="Time:"
        showTimeInput
        onChange={onChangeDate} />
    </div>
  );
}

const DateOnlyPicker: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  onChangeDateTime,
  onChangeDate,
  required = false
}) => {
  return (
    <div className="col-sm-12 col-md-12">
      <label>
        {title}
        {
          required === true &&
          <span style={{ marginLeft: 5, color: 'red'}}>*</span>
        }
      </label>
      <input type="hidden" value={value} name={name}/>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        name={name}
        className={'form-control'}
        selected={value} 
        disabled={disabled}
        // timeInputLabel="Time:"
        // showTimeInput
        onChange={onChangeDate} />
    </div>
  );
}

const SelectWithText: React.FC<FormInput> = ({
  title,
  placeholder,
  onChange,
  type,
  value,
  name,
  disabled = false,
  onChangeSelectWithText,
  selectData,
  onInputChange,
  required = false,
}) => {

  return (
    <div className="col-sm-12 col-md-12">
      <label className="">
        {title}
        {
          required === true &&
          <span style={{ marginLeft: 5, color: 'red'}}>*</span>
        }
      </label>
      <Select 
        options={selectData} 
        placeholder={placeholder}
        onChange={onChangeSelectWithText}
        name={name}
        value={value}
        isDisabled={disabled}
        onInputChange={onInputChange}
      />
    </div>  
  )
}

const Toggle: React.FC<FormInput> = ({ title, name, onChange, checked, disabled = false, required = false }) => {
  return(
    <div className="col-sm-12 col-md-12" style={{
      marginTop: 10,
      marginBottom: 10
    }}>
      <Form.Switch
        type="switch"
        label={title}
        required={required}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}

export {
  Input,
  InputTextArea,
  DateTimePicker,
  SelectWithText,
  Toggle,
  DateOnlyPicker
}