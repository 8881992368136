import create from "zustand";
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from "../../forms/interfaces/form-input.interface";
import {
  EventData,
  EventResultInterface,
} from "../interfaces/event-result.interface";
import { EventSingleResultInterface } from "../interfaces/event-single-result.interface";
import {
  DeleteEvent,
  GetEventParam,
  GetEvents,
  ShowEvent,
  SubmitEvent,
  UpdateEvent,
} from "../providers/disaster.provider";
import { toast } from "react-toastify";
import { generateFormLogic } from "../../../helpers/generate-form-logic";
import moment from "moment";

interface DisasterState {
  datas: EventData[];
  result?: EventResultInterface;
  disasterSelectData: SelectData[];
  loading: boolean;
  getEvents: (eventParam: GetEventParam) => void;
  deleteEvent: (id: string) => void;
  getEvent?: (eventId: string) => void;
  eventData?: EventData;
}

interface DisasterFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: EventSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const useDisasterStore = create<DisasterState>((set, get) => ({
  datas: [],
  disasterSelectData: [],
  loading: false,
  result: undefined,
  eventData: undefined,
  getEvent: async (eventId: string) => {
    const result = await ShowEvent(eventId);
    set({ eventData: result.data });
  },
  getEvents: async (eventParam: GetEventParam) => {
    set({ loading: true });
    const result = await GetEvents(eventParam);
    set({ result: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformDatas.push({
              value: item.id,
              label: `${item.event_code} - ${item.name} di ${item.city?.name}, ${item.province?.name}`,
            });
          }
          set({
            datas: result.data.items,
            disasterSelectData: transformDatas,
          });
        } else {
          set({
            datas: [],
            disasterSelectData: [],
          });
        }
      } else {
        set({
          datas: [],
          disasterSelectData: [],
        });
      }
    } else {
      set({
        datas: [],
        disasterSelectData: [],
      });
    }
    set({ loading: false });
  },
  deleteEvent: async (id: string) => {
    set({ loading: true });
    const result = await DeleteEvent(id);
    if (result.status) {
      toast.success(result.message);
      get().getEvents({ size: 10, page: 0 });
    } else {
      toast.error(result.message);
    }
    set({ loading: false });
  },
}));

const eventFormInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
};

const useDisasterFormStore = create<DisasterFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    };
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    };
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowEvent(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
  },
  generateForms: (formParam: FormProps) => {
    set({ forms: [], formParam: formParam, submitDone: undefined });
    let data: EventData = undefined;
    if (formParam.data !== undefined) {
      data = formParam.data as EventData;
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: "Title",
        placeholder: "Title...",
        type: "text",
        name: "name",
        value: data?.name ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Description",
        placeholder: "Description...",
        type: "text-area",
        name: "description",
        value: data?.description,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Tanggal dan Waktu",
        placeholder: "Tanggal dan Waktu...",
        type: "datetime",
        name: "date_time",
        value: moment(data?.date_time).toDate(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Kategori",
        placeholder: "Kategori...",
        type: "select-with-text",
        name: "event_type_id",
        value: {
          value: data?.event_type?.id,
          label: data?.event_type?.name,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Latitude",
        placeholder: "Latitude...",
        type: "text",
        name: "lat",
        value: data?.lat ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Longitude",
        placeholder: "Longitude...",
        type: "text",
        name: "lng",
        value: data?.lng ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Provinsi",
        placeholder: "Provinsi...",
        type: "select-with-text",
        name: "province_id",
        value: {
          value: data?.province?.id,
          label: data?.province?.name,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Kota/kabupaten",
        placeholder: "Kota/kabupaten...",
        type: "select-with-text",
        name: "city_id",
        value: {
          value: data?.city?.id,
          label: data?.city?.name,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "By Telkomsel",
        placeholder: "By Telkomsel...",
        type: "toggle",
        name: "is_terra",
        value: data?.is_terra ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Active",
        placeholder: "Active...",
        type: "toggle",
        name: "is_active",
        value: data?.is_active ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Total Bantuan CSR",
        placeholder: "Total Bantuan CSR...",
        type: "number",
        name: "total_csr_assistance",
        value: data?.total_csr_assistance ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    // check required field
    let errorExist = false;
    for (const v of get().forms) {
      if (v.required === true && (v.value === undefined || v.value === "")) {
        toast.error(`Please fill ${v.title}`);
        errorExist = true;
      }
    }

    if (errorExist === true) {
      return;
    }

    try {
      set({ formLoading: true });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error("Cant submit form, because its read-only");
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ""),
        };
      }

      let res: EventSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE)
        res = await SubmitEvent(formData);
      if (get().formParam.action === FormAction.UPDATE)
        res = await UpdateEvent(get().formParam.id, formData);

      if (res.status) {
        toast.success(res.message);
        set({ forms: [], submitDone: true });
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message ?? res.error);
        }
        set({ submitDone: false });
      }
      set({ formLoading: false });
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
    }
  },
  reset: () => {
    set(eventFormInitialState);
  },
}));

export { useDisasterStore, useDisasterFormStore };
