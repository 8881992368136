import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { EventGeneralInfoResultInterface } from "../interfaces/event-general-info-result.interface";
import { EventGeneralInfoSingleResultInterface } from "../interfaces/event-general-info-single-result.interface";

export interface EventGeneralInfoParam {
  size: number;
  page: number;
  eventId: string;
  code: number;
}

export const GetEventGeneralInfos = async (param: EventGeneralInfoParam): Promise<EventGeneralInfoResultInterface> => {
  try {
    const result = await ApiDisaster.get("/event-general-infos", {
      params: {
        size: param.size,
        page: param.page,
        code: param.code,
        event_id: param.eventId
      }
    });
    return result.data as EventGeneralInfoResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowEventGeneralInfo = async (id: string): Promise<EventGeneralInfoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.get(
      '/event-general-infos/' + id,
    );
    return response.data as EventGeneralInfoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventGeneralInfoSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitEventGeneralInfo = async (form: any): Promise<EventGeneralInfoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.post(
      '/event-general-infos',
      form
    );
    return response.data as EventGeneralInfoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventGeneralInfoSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateEventGeneralInfo = async (id: string, form: any): Promise<EventGeneralInfoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.put(
      '/event-general-infos/' + id,
      form
    );
    return response.data as EventGeneralInfoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventGeneralInfoSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteEventGeneralInfo = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.delete(
      '/event-general-infos/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}