import { toast } from "react-toastify";
import create from "zustand";
import { generateFormLogic } from "../../../helpers/generate-form-logic";
import { EventSingleResultInterface } from "../../disaster/interfaces/event-single-result.interface";
import {
  SelectData,
  FormInput,
  FormAction,
  FormProps,
} from "../../forms/interfaces/form-input.interface";
import {
  ItemData,
  ItemResultInterface,
} from "../interfaces/item-result.interface";
import { ItemSingleResultInterface } from "../interfaces/item-single-result.interface";
import {
  ItemParam,
  GetItems,
  DeleteItem,
  ShowItem,
  SubmitItem,
  UpdateItem,
} from "../providers/items.provider";

interface ItemState {
  items: ItemData[];
  itemModel?: ItemResultInterface;
  itemLoading: boolean;
  itemSelectDatas: SelectData[];
  getItems: (itemParam: ItemParam) => void;
  deleteItem: (id: string) => void;
}
const useItemStore = create<ItemState>((set, get) => ({
  items: [],
  itemSelectDatas: [],
  itemModel: undefined,
  itemLoading: false,
  getItems: async (itemParam: ItemParam) => {
    set({ itemLoading: true });
    const result = await GetItems(itemParam);
    set({ itemModel: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformsDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformsDatas.push({
              label: item.name,
              value: item.id,
            });
          }
          set({
            items: result.data.items,
            itemSelectDatas: transformsDatas,
          });
        } else {
          set({
            items: [],
            itemSelectDatas: [],
          });
        }
      } else {
        set({
          items: [],
          itemSelectDatas: [],
        });
      }
    } else {
      set({
        items: [],
        itemSelectDatas: [],
      });
    }
    set({ itemLoading: false });
  },
  deleteItem: async (id: string) => {
    set({ itemLoading: true });
    const result = await DeleteItem(id);
    if (result.status) {
      toast.success(result.message);
      get().getItems({ size: 20, page: 0 });
    } else {
      toast.error(result.message);
    }
    set({ itemLoading: false });
  },
}));

interface ItemFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: ItemSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const eventFormInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
};

const useItemFormStore = create<ItemFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    };
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    };
    console.log("asdasd_asd", forms[index]);
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowItem(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
  },
  generateForms: (formParam: FormProps) => {
    set({ forms: [], formParam: formParam, submitDone: undefined });
    let data: ItemData = undefined;
    if (formParam.data !== undefined) {
      data = formParam.data as ItemData;
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: "Name",
        placeholder: "Name...",
        type: "text",
        name: "name",
        value: data?.name ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: data?.value_type,
        placeholder: "Value Type...",
        type: "select-with-text",
        name: "value_type",
        selectData: [
          { value: "number", label: "number" },
          { value: "text", label: "text" },
        ],
        value: {
          label: data?.value_type ?? "",
          value: data?.value_type ?? "",
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    // check required field
    let errorExist = false;
    for (const v of get().forms) {
      if (v.required === true && (v.value === undefined || v.value === "")) {
        toast.error(`Please fill ${v.title}`);
        errorExist = true;
      }
    }

    if (errorExist === true) {
      return;
    }

    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error("Cant submit form, because its read-only");
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ""),
        };
      }

      let res: EventSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE)
        res = await SubmitItem(formData);
      if (get().formParam.action === FormAction.UPDATE)
        res = await UpdateItem(get().formParam.id, formData);

      if (res.status) {
        toast.success(res.message);
        set({ forms: [], submitDone: true });
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message ?? res.error);
        }
        set({ submitDone: false });
      }
      set({ formLoading: false });
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
    }
  },
  reset: () => {
    set(eventFormInitialState);
  },
}));

export { useItemStore, useItemFormStore };
