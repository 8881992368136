interface PageHeaderComponentProps {
  title?: string;
  subtitle?: string;
  hasButton?: boolean;
  buttonTitle?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const PageHeaderComponent: React.FC<PageHeaderComponentProps> = ({
  title,
  subtitle,
  hasButton = false,
  buttonTitle,
  onClick,
}) => {
  return (
    <div className="page-header">
      <h4 className="page-title text-white" style={{ marginTop: -30 }}>{ title ?? '' }</h4>
      <ol className="breadcrumb">
        <li className="breadcrumb-item text-white">{ subtitle ?? '' }</li>
      </ol>
      {
        hasButton &&
        <button 
          type="button" 
          className="btn btn-outline-light" 
          // data-toggle="modal" 
          // data-target="#disasterAdd"
          onClick={onClick}>
            <i className="fa fa-plus mr-2" />
            { buttonTitle ?? 'Button Title'}
          </button>
      }
    </div>
  );
}

export default PageHeaderComponent;