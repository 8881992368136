import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { PotencyPhotoResultInterface } from "../interfaces/potency-photo-result.interface";
import { PotencyPhotoSingleResultInterface } from "../interfaces/potency-photo-single-result.interface";

export interface PotencyPhotoParam {
  size: number;
  page: number;
}

export const GetPotencyPhotos = async (
  param: PotencyPhotoParam
): Promise<PotencyPhotoResultInterface> => {
  try {
    const result = await ApiDisaster.get("/potency-photos", {
      params: {
        size: param.size,
        page: param.page,
      },
    });
    return result.data as PotencyPhotoResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowPotencyPhoto = async (
  id: string
): Promise<PotencyPhotoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.get("/potency-photos/" + id);
    return response.data as PotencyPhotoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: PotencyPhotoSingleResultInterface = a.response?.data;
    return response;
  }
};

export const SubmitPotencyPhoto = async (
  form: any
): Promise<PotencyPhotoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.post("/potency-photos", form);
    return response.data as PotencyPhotoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: PotencyPhotoSingleResultInterface = a.response?.data;
    return response;
  }
};

export const UpdatePotencyPhoto = async (
  id: string,
  form: any
): Promise<PotencyPhotoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.put("/potency-photos/" + id, form);
    return response.data as PotencyPhotoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: PotencyPhotoSingleResultInterface = a.response?.data;
    return response;
  }
};

export const DeletePotencyPhoto = async (
  id: string
): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.delete("/potency-photos/" + id);
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};
