import { toast } from "react-toastify";
import create from "zustand";
import { UserSingleResultInterface } from "../../users/interfaces/user-single-result.interface";
import { GetMe, Login } from "../providers/auth.provider";

export interface AuthState {
  message: string;
  loading: boolean;
  isLoggedIn: boolean;
  meModel?: UserSingleResultInterface;
  login: (username: string, password: string) => void;
  checkAuth: () => void;
  logout: () => void;
  getMe: () => void;
}

export const useAuthStore = create<AuthState>((set, get) => ({
  message: "",
  loading: false,
  isLoggedIn: false,
  meModel: undefined,
  checkAuth: () => {
    const token = localStorage.getItem("token");
    if (token) {
      get().getMe();
      set({ isLoggedIn: true });
    } else {
      set({ isLoggedIn: false });
    }
  },
  login: async (username: string, password: string) => {
    set({ loading: true });
    const response = await Login(username, password);

    if (response.status) {
      localStorage.setItem("token", response.data.accessToken);
      get().getMe();
      set({ isLoggedIn: true });
    } else {
      toast.error(response.message);
    }

    set({
      message: response.message,
      loading: false,
    });
  },
  logout: () => {
    console.log("test");
    localStorage.removeItem("token");
    set({ isLoggedIn: false });
  },
  getMe: async () => {
    const response = await GetMe();
    set({ meModel: response });
  },
}));
