import { FormInput } from "../interfaces/form-input.interface";
import { FileUploadV2, FileUploadVideoV2 } from "./file-upload-v2.component";
import {
  DateOnlyPicker,
  DateTimePicker,
  Input,
  InputTextArea,
  SelectWithText,
  Toggle,
} from "./input.component";

interface FormsComponentProps {
  forms: FormInput[];
  setForms: (
    index: number,
    value: any,
    isVideo?: boolean,
    thumbnail?: string
  ) => void;
  selectWithTextCb?: (index: number, name: string, value: string) => void;
  selectWithTextInputChangeCb?: (
    index: number,
    name: string,
    value: string
  ) => void;
}

const FormsComponent: React.FC<FormsComponentProps> = ({
  forms,
  setForms,
  selectWithTextCb = (index: number, name: string, value: string) => {},
  selectWithTextInputChangeCb = (
    index: number,
    name: string,
    value: string
  ) => {},
}) => {
  return (
    <>
      {forms !== undefined ? (
        forms?.map((v, k) => {
          switch (v?.type) {
            case "file-upload-video-v2":
              return (
                <FileUploadVideoV2
                  key={k}
                  title={v.title}
                  multiple={false}
                  description={v.placeholder}
                  name={v.name}
                  onUploadDone={(e) => {
                    setForms(k, e.data?.id, true, e.data?.thumbnail);
                  }}
                  value={v.value}
                  disabled={v.disabled}
                  isVideo={true}
                  required={v.required}
                />
              );
            case "file-upload-v2":
              return (
                <FileUploadV2
                  key={k}
                  title={v.title}
                  multiple={false}
                  description={v.placeholder}
                  name={v.name}
                  onUploadDone={(e) => {
                    setForms(k, e.data?.id);
                  }}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                />
              );
            case "text":
              return (
                <Input
                  key={k}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  onChange={(e) => {
                    setForms(k, e.target.value);
                  }}
                  required={v.required}
                />
              );
            case "number":
              return (
                <Input
                  key={k}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  onChange={(e) => {
                    setForms(k, e.target.value);
                  }}
                  required={v.required}
                />
              );
            case "password":
              return (
                <Input
                  key={k}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  onChange={(e) => {
                    setForms(k, e.target.value);
                  }}
                  required={v.required}
                />
              );
            case "text-area":
              return (
                <InputTextArea
                  key={k}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  onChangeTextArea={(e) => {
                    setForms(k, e.target.value);
                  }}
                  required={v.required}
                />
              );
            case "datetime":
              return (
                <DateTimePicker
                  key={k}
                  title={v.title}
                  placeholder={v.placeholder}
                  type={v.type}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  onChangeDate={(value) => {
                    setForms(k, value);
                  }}
                  required={v.required}
                />
              );
            case "date":
              return (
                <DateOnlyPicker
                  key={k}
                  title={v.title}
                  placeholder={v.placeholder}
                  type={v.type}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  onChangeDate={(value) => {
                    setForms(k, value);
                  }}
                  required={v.required}
                />
              );
            case "select-with-text":
              return (
                <SelectWithText
                  key={k}
                  title={v.title}
                  name={v.name}
                  selectData={v.selectData}
                  type={v.type}
                  value={v.value}
                  disabled={v.disabled}
                  onChangeSelectWithText={(e) => {
                    setForms(k, e);
                    selectWithTextCb(k, v.name, e.value);
                  }}
                  onInputChange={(e) => {
                    // v.onInputChange(e)
                    selectWithTextInputChangeCb(k, v.name, e);
                  }}
                  required={v.required}
                />
              );
            case "toggle":
              return (
                <Toggle
                  key={k}
                  title={v.title}
                  name={v.name}
                  type={v.type}
                  checked={v.value}
                  disabled={v.disabled}
                  onChange={(event) => {
                    const target = event.target;
                    const value =
                      target.type === "checkbox"
                        ? target.checked
                        : target.value;
                    setForms(k, value);
                  }}
                  required={v.required}
                />
              );
            default:
              return <div key={k}></div>;
          }
        })
      ) : (
        <></>
      )}
    </>
  );
};

interface ControlUIProps {
  onClickDeleteDrawing: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const ControlUI: React.FC<ControlUIProps> = ({ onClickDeleteDrawing }) => {
  return (
    <>
      {/* delete drawing button */}
      <button onClick={(e) => console.log(e)}>Delete Drawing</button>
    </>
  );
};

const DisasterMaps = () => {
  const handleOnClickDeleteDrawing = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    console.log("delete");
  };

  return (
    <>
      <ControlUI onClickDeleteDrawing={handleOnClickDeleteDrawing} />
    </>
  );
};

export default FormsComponent;
