import { useEffect, useState } from "react";
import { Modal, Button, Table, ButtonGroup } from "react-bootstrap";
import { FormAction } from "../../forms/interfaces/form-input.interface";
import PageHeaderComponent from "../../home/components/page-header.component";
import { useReportFormStore, useReportStore } from "../states/report.state";
import ReactPaginate from "react-paginate";
import FormsComponent from "../../forms/components/forms.component";
import { ReportData } from "../interfaces/report-single-result.interface";
import { useDashboardStore } from "../../dashboard/states/dashboard.state";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import ReportPdfScreen from "./report-pdf.screen";
import { toast } from "react-toastify";
import ReportFormScreen from "./report-form.screen";
import { timeDateFormatter } from "../../../helpers/datetime-formatter";

const ReportScreen = () => {
  const {
    setSelectedReport,
    getReports,
    reports,
    reportModel,
    reportLoading,
    deleteReport,
  } = useReportStore();
  const {
    submitDone,
    formLoading,
    forms,
    setForms,
    generateForms,
    setFormSelectData,
    onSubmitForm,
    formParam,
  } = useReportFormStore();
  const { selectedDisaster } = useDashboardStore();
  const [show, setShow] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);

  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    console.log("asdasd", forms);
  }, [forms]);

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: ReportData
  ) => {
    // generateForms({
    //   action: FormAction.UPDATE,
    //   title: 'Report',
    //   subtitle: 'Update Report',
    //   id: item.id,
    //   data: item
    // });
    // handleShow();
    setSelectedReport(item);
    navigate("form");
  };

  const handleView = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: ReportData
  ) => {
    // generateForms({
    //   action: FormAction.VIEW,
    //   title: 'Report',
    //   subtitle: 'View Report',
    //   id: item.id,
    //   data: item
    // });
    // handleShow();
    setSelectedReport(item);
    navigate("pdf");
  };

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  useEffect(() => {
    if (selectedDisaster !== undefined && selectedDisaster !== null) {
      getReports({
        size: size,
        page: page,
        event_id: selectedDisaster?.value,
      });
    }
  }, [page, selectedDisaster]);

  useEffect(() => {
    console.log(submitDone);
    if (submitDone !== undefined) {
      if (submitDone) {
        handleClose();
        generateForms({
          action: FormAction.CREATE,
          title: "Report",
          subtitle: "Tambah Report",
        });
        getReports({
          size: size,
          page: 0,
          event_id: selectedDisaster?.value,
        });
      }
    }
  }, [submitDone]);

  return (
    <>
      <PageHeaderComponent
        title="Report"
        subtitle="Daftar Report"
        hasButton={true}
        buttonTitle="Tambah"
        onClick={(e) => {
          if (reports?.length > 0) {
            toast.error("Report already created");
            return;
          }
          navigate("form");
          // generateForms({
          //   action: FormAction.CREATE,
          //   title: 'Report',
          //   subtitle: 'Create Report',
          //   data: undefined,
          //   id: undefined,
          // });
          // handleShow();
        }}
      />
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="wd-1p">No</th>
                    <th className="wd-15p">Description</th>
                    <th className="wd-3p" style={{ width: 50 }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportLoading && (
                    <tr>
                      <td colSpan={7}>Loading</td>
                    </tr>
                  )}
                  {reports.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          Report Created At{" "}
                          {timeDateFormatter(Date.parse(item.createdAt))}
                        </td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="primary"
                              onClick={(e) => handleView(e, item)}
                            >
                              Lihat
                            </Button>
                            <Button
                              variant="warning"
                              onClick={(e) => handleEdit(e, item)}
                            >
                              Ubah
                            </Button>
                            <Button
                              variant="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                const response = window.confirm(
                                  "Apakah yakin ingin menghapus data ini ?"
                                );
                                if (response === true) {
                                  deleteReport(
                                    item.id,
                                    selectedDisaster?.value
                                  );
                                }
                              }}
                            >
                              Hapus
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="card-footer">
              <ReactPaginate
                previousLabel={"«"}
                nextLabel={"»"}
                breakLabel={"..."}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                pageCount={reportModel?.data?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-sm m-0 float-right"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-12">
          <Outlet />
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size={"lg"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitForm(selectedDisaster?.value);
          }}
        >
          <Modal.Header>
            <Modal.Title>{formParam?.subtitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormsComponent forms={forms} setForms={setForms} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => {
                if (!formLoading) handleClose();
              }}
              className={formLoading && "btn-loading"}
            >
              Close
            </Button>
            <Button
              variant="danger"
              className={formLoading && "btn-loading"}
              onClick={(e) => {
                e.preventDefault();
                if (!formLoading) onSubmitForm(selectedDisaster?.value);
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

const ReportLayout = () => {
  return (
    <Routes>
      <Route>
        <Route path={"pdf"} element={<ReportPdfScreen />} />
        <Route path={"form"} element={<ReportFormScreen />} />
        <Route index element={<ReportScreen />} />
      </Route>
    </Routes>
  );
};

export default ReportLayout;
