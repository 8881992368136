import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { ItemResultInterface } from "../interfaces/item-result.interface";
import { ItemSingleResultInterface } from "../interfaces/item-single-result.interface";

export interface ItemParam {
  size: number;
  page: number;
}

export const GetItems = async (
  param: ItemParam
): Promise<ItemResultInterface> => {
  try {
    const result = await ApiDisaster.get("/items", {
      params: {
        size: param.size,
        page: param.page,
      },
    });
    return result.data as ItemResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowItem = async (
  id: string
): Promise<ItemSingleResultInterface> => {
  try {
    let response = await ApiDisaster.get("/items/" + id);
    return response.data as ItemSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ItemSingleResultInterface = a.response?.data;
    return response;
  }
};

export const GetTotalItem = async (): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.get("/total-items");
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};

export const SubmitItem = async (
  form: any
): Promise<ItemSingleResultInterface> => {
  try {
    let response = await ApiDisaster.post("/items", form);
    return response.data as ItemSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ItemSingleResultInterface = a.response?.data;
    return response;
  }
};

export const UpdateItem = async (
  id: string,
  form: any
): Promise<ItemSingleResultInterface> => {
  try {
    let response = await ApiDisaster.put("/items/" + id, form);
    return response.data as ItemSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ItemSingleResultInterface = a.response?.data;
    return response;
  }
};

export const DeleteItem = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.delete("/items/" + id);
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};
