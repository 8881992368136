import { AxiosError } from "axios";
import { ApiAuthorization, ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { UserOrganizationResultInterface } from "../interfaces/user-organization-result.interface";
import { UserOrganizationSingleResultInterface } from "../interfaces/user-organization-single-result.interface";

export interface UserOrganizationParam {
  size: number;
  page: number;
}

export const GetUserOrganizations = async (
  param: UserOrganizationParam
): Promise<UserOrganizationResultInterface> => {
  try {
    const result = await ApiAuthorization.get("/user-organizations", {
      params: {
        size: param.size,
        page: param.page,
      },
    });
    return result.data as UserOrganizationResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowUserOrganization = async (
  id: string
): Promise<UserOrganizationSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.get("/user-organizations/" + id);
    return response.data as UserOrganizationSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: UserOrganizationSingleResultInterface = a.response?.data;
    return response;
  }
};

export const GetTotalUserOrganization = async (): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorization.get("/total-user-organizations");
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};

export const SubmitUserOrganization = async (
  form: any
): Promise<UserOrganizationSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.post("/user-organizations", form);
    return response.data as UserOrganizationSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: UserOrganizationSingleResultInterface = a.response?.data;
    return response;
  }
};

export const UpdateUserOrganization = async (
  id: string,
  form: any
): Promise<UserOrganizationSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.put(
      "/user-organizations/" + id,
      form
    );
    return response.data as UserOrganizationSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: UserOrganizationSingleResultInterface = a.response?.data;
    return response;
  }
};

export const DeleteUserOrganization = async (
  id: string
): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorization.delete("/user-organizations/" + id);
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};
