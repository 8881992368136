import {
  ProvinceData,
  ProvinceResultInterface,
} from "../interfaces/province-result.interface";
import create from "zustand";
import { ProvinceParam, GetProvinces } from "../providers/province.provider";
import { SelectData } from "../../forms/interfaces/form-input.interface";

interface DisasterState {
  provinceDatas: ProvinceData[];
  provinceSelectDatas: SelectData[];
  result?: ProvinceResultInterface;
  param: ProvinceParam;
  loading: boolean;
  getProvinces: () => void;
}

const useProvinceStore = create<DisasterState>((set, get) => ({
  provinceDatas: [],
  provinceSelectDatas: [],
  result: undefined,
  loading: false,
  param: {
    size: 100000,
    page: 0,
  },
  getProvinces: async () => {
    set({ loading: true });
    const result = await GetProvinces(get().param);
    set({ result: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformDatas.push({
              label: item.name,
              value: item.id,
            });
          }
          set({
            provinceDatas: result.data.items,
            provinceSelectDatas: transformDatas,
          });
        } else {
          set({
            provinceDatas: [],
            provinceSelectDatas: [],
          });
        }
      } else {
        set({
          provinceDatas: [],
          provinceSelectDatas: [],
        });
      }
    } else {
      set({
        provinceDatas: [],
        provinceSelectDatas: [],
      });
    }
    set({ loading: false });
  },
}));

export { useProvinceStore };
