import { useEffect, useState } from "react";
import Select from "react-select";
import { useDisasterStore } from "../../disaster/states/disaster.state";
import PageHeaderComponent from "../../home/components/page-header.component";
import { useDashboardStore } from "../states/dashboard.state";
import { SelectData } from "../../forms/interfaces/form-input.interface";

const DashboardScreen = () => {
  const { getEvents, disasterSelectData } = useDisasterStore();
  const {
    loading,
    disaster,
    setDisaster,
    selectedDisaster,
    setSelectedDisaster,
    getTotalEvents,
    totalEvents,
    getTotalVolunteers,
    totalVolunteer,
    totalOrganizations,
    getTotalUserOrganization,
  } = useDashboardStore();

  useEffect(() => {
    getTotalEvents();
    getTotalVolunteers();
    getTotalUserOrganization();
    getEvents({
      page: 0,
      size: 1000,
    });
  }, []);

  return (
    <>
      <PageHeaderComponent title="Beranda" />
      <div className="row row-cards">
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className="card bg-primary">
            <div className="card-body">
              <div className="d-flex no-block align-items-center">
                <div>
                  <h6 className="text-white">Bencana</h6>
                  <h2 className="text-white m-0 ">
                    <span id="dashboardEvents">{totalEvents}</span>
                  </h2>
                </div>
                <div className="ml-auto">
                  <span className="text-white display-6">
                    <i className="fa fa-codepen fa-2x" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className="card bg-warning">
            <div className="card-body">
              <div className="d-flex no-block align-items-center">
                <div>
                  <h6 className="text-white">Relawan</h6>
                  <h2 className="text-white m-0 ">
                    <span id="dashboardVolunteers">{totalVolunteer}</span> Orang
                  </h2>
                </div>
                <div className="ml-auto">
                  <span className="text-white display-6">
                    <i className="fa fa-group fa-2x" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <div className="card bg-info">
            <div className="card-body">
              <div className="d-flex no-block align-items-center">
                <div>
                  <h6 className="text-white">Organisasi</h6>
                  <h2 className="text-white m-0 ">
                    <span id="dashboardOrganizations">
                      {totalOrganizations}
                    </span>
                  </h2>
                </div>
                <div className="ml-auto">
                  <span className="text-white display-6">
                    <i className="fa fa-group fa-2x" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="form-group">
            <label className="form-label">Bencana:</label>
            {/* {disasterSelectData?.length > 0 && (
              <Select
                options={disasterSelectData}
                value={selectedDisaster}
                onChange={(e) => {
                  setSelectedDisaster(e);
                }}
                onInputChange={(e) => {
                  console.log(e);
                  getEvents({
                    page: 0,
                    size: 1000,
                    name: e,
                  });
                }}
              />
            )} */}
            <Select
              options={disasterSelectData}
              value={selectedDisaster}
              onChange={(e) => {
                setSelectedDisaster(e);
              }}
              onInputChange={(e) => {
                console.log(e);
                getEvents({
                  page: 0,
                  size: 25,
                  name: e,
                });
              }}
            />
          </div>
          <div className="form-group float-right">
            <button
              type="button"
              className="btn btn-primary"
              id="dashboardEventSelectBtn"
              onClick={(e) => {
                setDisaster(selectedDisaster.value);
              }}
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardScreen;
