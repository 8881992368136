import {
  UserData,
  UserResultInterface,
} from "../interfaces/user-result.interface";
import create from "zustand";
import {
  DeleteUser,
  GetUsers,
  UserParam,
  ShowUser,
  SubmitUser,
  UpdateUser,
  GetUsersVolunteer,
} from "../providers/user.provider";
import { toast } from "react-toastify";
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from "../../forms/interfaces/form-input.interface";
import { generateFormLogic } from "../../../helpers/generate-form-logic";
import { UserSingleResultInterface } from "../interfaces/user-single-result.interface";

interface UserState {
  users: UserData[];
  userModel?: UserResultInterface;
  userLoading: boolean;
  userSelectDatas: SelectData[];
  getUsers: (userParam: UserParam) => void;
  getUsersVolunteer: (userParam: UserParam) => void;
  deleteUser: (id: string) => void;
  userData?: UserData;
  getUser?: (userId: string) => void;
}
const useUserStore = create<UserState>((set, get) => ({
  users: [],
  userSelectDatas: [],
  userModel: undefined,
  userLoading: false,
  userData: undefined,
  getUser: async (userId: string) => {
    const response = await ShowUser(userId);
    set({ userData: response.data });
  },
  getUsersVolunteer: async (userParam: UserParam) => {
    console.log("asdasd", userParam);
    set({ userLoading: true });
    const result = await GetUsersVolunteer(userParam);
    set({ userModel: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformsDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformsDatas.push({
              label: item.fullName,
              value: item.id,
            });
          }
          set({ users: result.data.items, userSelectDatas: transformsDatas });
        } else {
          set({
            users: [],
            userSelectDatas: [],
          });
        }
      } else {
        set({
          users: [],
          userSelectDatas: [],
        });
      }
    } else {
      set({
        users: [],
        userSelectDatas: [],
      });
    }
    set({ userLoading: false });
  },
  getUsers: async (userParam: UserParam) => {
    set({ userLoading: true });
    const result = await GetUsers(userParam);
    set({ userModel: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformsDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformsDatas.push({
              label: item.fullName,
              value: item.id,
            });
          }
          set({ users: result.data.items, userSelectDatas: transformsDatas });
        } else {
          set({
            users: [],
            userSelectDatas: [],
          });
        }
      } else {
        set({
          users: [],
          userSelectDatas: [],
        });
      }
    } else {
      set({
        users: [],
        userSelectDatas: [],
      });
    }
    set({ userLoading: false });
  },
  deleteUser: async (id: string) => {
    set({ userLoading: true });
    const result = await DeleteUser(id);
    if (result.status) {
      toast.success(result.message);
      get().getUsers({ size: 20, page: 0 });
    } else {
      toast.error(result.message);
    }
    set({ userLoading: false });
  },
}));

interface UserFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: UserSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const userFormInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
};

const useUserFormStore = create<UserFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    };
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    };
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowUser(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
  },
  generateForms: (formParam: FormProps) => {
    set({ forms: [], formParam: formParam, submitDone: undefined });
    let data: UserData = undefined;
    if (formParam.data !== undefined) {
      data = formParam.data as UserData;
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: "Full Name",
        placeholder: "Full Name...",
        type: "text",
        name: "fullName",
        value: data?.fullName ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Email",
        placeholder: "Email...",
        type: "text",
        name: "email",
        value: data?.email ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Username",
        placeholder: "Username...",
        type: "text",
        name: "username",
        value: data?.username ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "No. HP",
        placeholder: "No. HP...",
        type: "text",
        name: "phone_number",
        value: data?.phone_number ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Password",
        placeholder: "Password...",
        type: "password",
        name: "password",
        value: "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Role",
        placeholder: "Role...",
        type: "select-with-text",
        name: "role_id",
        selectData: [],
        value: {
          label: data?.role?.name,
          value: data?.role?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    // check required field
    let errorExist = false;
    for (const v of get().forms) {
      if (v.required === true && (v.value === undefined || v.value === "")) {
        toast.error(`Please fill ${v.title}`);
        errorExist = true;
      }
    }

    if (errorExist === true) {
      return;
    }

    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error("Cant submit form, because its read-only");
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ""),
        };
      }

      let res: UserSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE)
        res = await SubmitUser(formData);
      if (get().formParam.action === FormAction.UPDATE)
        res = await UpdateUser(get().formParam.id, formData);

      if (res.status) {
        toast.success(res.message);
        set({ forms: [], submitDone: true });
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message ?? res.error);
        }
        set({ submitDone: false });
      }
      set({ formLoading: false });
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
    }
  },
  reset: () => {
    set(userFormInitialState);
  },
}));

export { useUserStore, useUserFormStore };
