import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FormsComponent from "../../forms/components/forms.component";
import { DateOnlyPicker, Input } from "../../forms/components/input.component";
import {
  FormAction,
  SelectData,
} from "../../forms/interfaces/form-input.interface";
import { useUserOrganizationStore } from "../../organizations/states/user-organization.state";
import { useUserDetailFormStore } from "../states/user-detail.state";

interface UserDetailFormComponentProps {
  isShow: boolean;
  userDetailId: string;
  userId: string;
  handleClose: () => void;
  handleShow: () => void;
}

const UserDetailFormComponent: React.FC<UserDetailFormComponentProps> = ({
  isShow,
  handleClose,
  handleShow,
  userDetailId,
  userId,
}) => {
  const {
    submitDone,
    formLoading,
    forms,
    setForms,
    generateForms,
    setFormSelectData,
    onSubmitForm,
    formParam,
    fetchSingleData,
  } = useUserDetailFormStore();
  const { userOrganizationSelectDatas, getUserOrganizations } =
    useUserOrganizationStore();

  useEffect(() => {
    getUserOrganizations({
      size: 100000,
      page: 0,
    });
  }, [isShow]);

  useEffect(() => {
    console.log("asdasd_asd", forms);
  }, [forms]);

  useEffect(() => {
    generateForms({
      action: FormAction.CREATE,
      title: "User Detail",
      subtitle: "Tambah User Detail",
    });
  }, []);

  useEffect(() => {
    if (userOrganizationSelectDatas.length > 0) {
      console.log("zxczxc", userOrganizationSelectDatas);
      setFormSelectData(4, userOrganizationSelectDatas);
    }
  }, [userOrganizationSelectDatas]);

  useEffect(() => {
    if (userDetailId !== undefined) {
      fetchSingleData(userDetailId);
    } else {
      generateForms({
        action: FormAction.CREATE,
        title: "User Detail",
        subtitle: "Tambah User Detail",
      });
    }
  }, [userDetailId]);

  useEffect(() => {
    if (submitDone !== undefined) {
      if (submitDone) {
        handleClose();
        generateForms({
          action: FormAction.CREATE,
          title: "User Detail",
          subtitle: "Tambah User Detail",
        });
      }
    }
  }, [submitDone]);

  return (
    <Modal show={isShow} onHide={handleClose} size={"lg"}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formParam?.subtitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormsComponent forms={forms} setForms={setForms} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              if (!formLoading) handleClose();
            }}
            className={formLoading && "btn-loading"}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className={formLoading && "btn-loading"}
            onClick={(e) => {
              e.preventDefault();
              if (!formLoading) onSubmitForm(userId);
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UserDetailFormComponent;
