import moment from "moment"

export const dateTimeFormatter = (unix: number) => {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    return moment(unix).format('MMMM Do, YYYY');
  }
  return moment(unix).subtract(7, 'hours').format('MMMM Do, YYYY');
}

export const timeFormatter = (unix: number) => {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    return moment(unix).format('LT');
  }
  return moment(unix).subtract(7, 'hours').format('LT');
}

export const timeDateFormatter = (unix: number) => {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    return moment(unix).format('dddd, L, H:mm');
  }
  return moment(unix).subtract(7, 'hours').format('dddd, L, H:mm');
}

// export const matchDayFormatter = (unix: number) => {
//   return moment(unix).format
// }