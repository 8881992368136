import { useEffect, useState } from "react";
import { Table, ButtonGroup, Button, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDashboardStore } from "../../dashboard/states/dashboard.state";
import { FileUploadV2 } from "../../forms/components/file-upload-v2.component";
import FormsComponent from "../../forms/components/forms.component";
import {
  Input,
  InputTextArea,
  DateTimePicker,
  SelectWithText,
  DateOnlyPicker,
} from "../../forms/components/input.component";
import { FormAction } from "../../forms/interfaces/form-input.interface";
import PageHeaderComponent from "../../home/components/page-header.component";
import { useInfoSourceStore } from "../../info-sources/states/info-source.state";
import { useUserStore } from "../../users/states/user.state";
import { EventGeneralInfoData } from "../interfaces/event-general-info-result.interface";
import {
  useEventGeneralInfoStore,
  useEventGeneralInfoFormStore,
} from "../states/event-general-info.state";

interface EventGeneralInfoScreenProps {
  code: number;
}

const EventGeneralInfoScreen: React.FC<EventGeneralInfoScreenProps> = ({
  code,
}) => {
  const {
    getEventGeneralInfos,
    eventGeneralInfos,
    eventGeneralInfoLoading,
    eventGeneralInfoModel,
    deleteEventGeneralInfo,
  } = useEventGeneralInfoStore();
  const {
    submitDone,
    formLoading,
    forms,
    setForms,
    generateForms,
    setFormSelectData,
    onSubmitForm,
    formParam,
  } = useEventGeneralInfoFormStore();
  const { getInfoSources, infoSourceSelectDatas } = useInfoSourceStore();
  const { selectedDisaster } = useDashboardStore();
  const [show, setShow] = useState<boolean>(false);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [pageTitle, setPageTitle] = useState<string>("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    getInfoSources({
      page: 0,
      size: 100,
    });
    setShow(true);
  };

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: EventGeneralInfoData
  ) => {
    generateForms({
      action: FormAction.UPDATE,
      title: pageTitle,
      subtitle: "Ubah " + pageTitle,
      id: item.id,
      data: item,
      code: code,
      eventId: selectedDisaster?.value,
    });
    handleShow();
  };

  const handleView = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: EventGeneralInfoData
  ) => {
    generateForms({
      action: FormAction.VIEW,
      title: pageTitle,
      subtitle: "Lihat " + pageTitle,
      id: item.id,
      data: item,
      code: code,
      eventId: selectedDisaster?.value,
    });
    handleShow();
  };

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  useEffect(() => {
    switch (code) {
      case 10:
        setPageTitle("Berita TV");
        break;
      case 11:
        setPageTitle("Siaran Langsung");
        break;
      case 12:
        setPageTitle("Informasi Umum");
        break;
      default:
        setPageTitle("");
        break;
    }
  }, [code]);

  useEffect(() => {
    if (selectedDisaster !== undefined && code !== undefined) {
      getEventGeneralInfos({
        size: size,
        page: page,
        eventId: selectedDisaster.value,
        code: code,
      });
    }
  }, [selectedDisaster, code, page]);

  useEffect(() => {
    console.log(forms);
  }, [forms]);

  useEffect(() => {
    if (submitDone !== undefined) {
      if (submitDone) {
        handleClose();
        generateForms({
          action: FormAction.CREATE,
          title: pageTitle,
          subtitle: "Tambah " + pageTitle,
          code: code,
          eventId: selectedDisaster?.value,
        });
        getEventGeneralInfos({
          size: size,
          page: 0,
          code: code,
          eventId: selectedDisaster.value,
        });
        getInfoSources({
          page: 0,
          size: 100,
        });
      }
    }
  }, [submitDone]);

  useEffect(() => {
    if (infoSourceSelectDatas.length > 0) {
      setFormSelectData(1, infoSourceSelectDatas);
    }
  }, [infoSourceSelectDatas]);

  return (
    <>
      <PageHeaderComponent
        title={pageTitle}
        subtitle={`Daftar ${pageTitle}`}
        hasButton={true}
        buttonTitle="Tambah"
        onClick={(e) => {
          generateForms({
            action: FormAction.CREATE,
            title: pageTitle,
            subtitle: "Tambah " + pageTitle,
            data: undefined,
            id: undefined,
            code: code,
            eventId: selectedDisaster?.value,
          });
          handleShow();
        }}
      />

      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="wd-1p">No</th>
                    <th className="wd-15p">Judul</th>
                    <th className="wd-15p">Sumber</th>
                    <th className="wd-3p" style={{ width: 50 }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {eventGeneralInfoLoading && (
                    <tr>
                      <td colSpan={7}>Loading</td>
                    </tr>
                  )}
                  {eventGeneralInfos.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td>{item.info_source?.name}</td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="primary"
                              onClick={(e) => handleView(e, item)}
                            >
                              Lihat
                            </Button>
                            <Button
                              variant="warning"
                              onClick={(e) => handleEdit(e, item)}
                            >
                              Ubah
                            </Button>
                            <Button
                              variant="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                const response = window.confirm(
                                  "Apakah yakin ingin menghapus data ini ?"
                                );
                                if (response === true) {
                                  deleteEventGeneralInfo(item.id, {
                                    page: 0,
                                    size: size,
                                    code: code,
                                    eventId: selectedDisaster.value,
                                  });
                                }
                              }}
                            >
                              Hapus
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="card-footer">
              <ReactPaginate
                previousLabel={"«"}
                nextLabel={"»"}
                breakLabel={"..."}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                pageCount={eventGeneralInfoModel?.data?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-sm m-0 float-right"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size={"lg"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitForm();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{formParam?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormsComponent forms={forms} setForms={setForms} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => {
                if (!formLoading) handleClose();
              }}
              className={formLoading && "btn-loading"}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className={formLoading && "btn-loading"}
              onClick={(e) => {
                e.preventDefault();
                if (!formLoading) onSubmitForm();
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EventGeneralInfoScreen;
