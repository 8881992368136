import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { EventVideoResultInterface } from "../interfaces/event-video-result.interface";
import { EventVideoSingleResultInterface } from "../interfaces/event-video-single-result.interface";

export interface EventVideoParam {
  size: number;
  page: number;
  event_id: string;
}

export const GetEventVideos = async (param: EventVideoParam): Promise<EventVideoResultInterface> => {
  try {
    const result = await ApiDisaster.get("/event-videos", {
      params: {
        size: param.size,
        page: param.page,
        event_id: param.event_id
      }
    });
    return result.data as EventVideoResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowEventVideo = async (id: string): Promise<EventVideoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.get(
      '/event-videos/' + id,
    );
    return response.data as EventVideoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventVideoSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitEventVideo = async (form: any): Promise<EventVideoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.post(
      '/event-videos',
      form
    );
    return response.data as EventVideoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventVideoSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateEventVideo = async (id: string, form: any): Promise<EventVideoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.put(
      '/event-videos/' + id,
      form
    );
    return response.data as EventVideoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventVideoSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteEventVideo = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.delete(
      '/event-videos/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}