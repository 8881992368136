import { EventTypeData, EventTypeResultInterface } from "../interfaces/event-type-result.interface";
import create from 'zustand'
import { EventTypeParam, GetEventTypes } from "../providers/event-type.provider";
import { SelectData } from "../../forms/interfaces/form-input.interface";

interface DisasterState {
  eventTypeDatas: EventTypeData[];
  eventTypeSelectDatas: SelectData[];
  result?: EventTypeResultInterface;
  param: EventTypeParam;
  loading: boolean;
  getEventTypes: () => void;
}

const useEventTypeStore = create<DisasterState>((set, get) => ({
  eventTypeDatas: [],
  eventTypeSelectDatas: [],
  result: undefined,
  loading: false,
  param: {
    size: 100,
    page: 0
  },
  getEventTypes: async () => {
    set({ loading: true });
    const result = await GetEventTypes(get().param);
    set({ result: result })
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformDatas.push({
              label: item.name,
              value: item.id
            });
          }
          set({ 
            eventTypeDatas: result.data.items,
            eventTypeSelectDatas: transformDatas 
          });
        } else {
          set({ 
            eventTypeDatas: [],
            eventTypeSelectDatas: []
          });
        }
      } else {
        set({ 
          eventTypeDatas: [],
          eventTypeSelectDatas: []
        });
      }
    } else {
      set({ 
        eventTypeDatas: [],
        eventTypeSelectDatas: []
      });
    }
    set({ loading: false })
  }
}))

export {
  useEventTypeStore
}