import { AxiosError } from "axios";
import { ApiAuthorization, ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { RoleResultInterface } from "../interfaces/role-result.interface";
import { RoleSingleResultInterface } from "../interfaces/role-single-result.interface";

export interface RoleParam {
  size: number;
  page: number;
}

export const GetRoles = async (param: RoleParam): Promise<RoleResultInterface> => {
  try {
    const result = await ApiAuthorization.get("/roles", {
      params: {
        size: param.size,
        page: param.page
      }
    });
    return result.data as RoleResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowRole = async (id: string): Promise<RoleSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.get(
      '/roles/' + id,
    );
    return response.data as RoleSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: RoleSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitRole = async (form: any): Promise<RoleSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.post(
      '/roles',
      form
    );
    return response.data as RoleSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: RoleSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateRole = async (id: string, form: any): Promise<RoleSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.put(
      '/roles/' + id,
      form
    );
    return response.data as RoleSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: RoleSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteRole = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorization.delete(
      '/roles/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}