import { AxiosError } from "axios";
import { ApiResource } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { ResourceResultInterface } from "../interfaces/resource-result.interface";
import { ResourceSingleResultInterface } from "../interfaces/resource-single-result.interface";

export interface ResourceParam {
  size: number;
  page: number;
}

export const GetResources = async (param: ResourceParam): Promise<ResourceResultInterface> => {
  try {
    const result = await ApiResource.get("/resources", {
      params: {
        size: param.size,
        page: param.page
      }
    });
    return result.data as ResourceResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowResource = async (id: string): Promise<ResourceSingleResultInterface> => {
  try {
    let response = await ApiResource.get(
      '/resources/' + id,
    );
    return response.data as ResourceSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ResourceSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitResource = async (form: any): Promise<ResourceSingleResultInterface> => {
  try {
    let response = await ApiResource.post(
      '/resources',
      form
    );
    return response.data as ResourceSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ResourceSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateResource = async (id: string, form: any): Promise<ResourceSingleResultInterface> => {
  try {
    let response = await ApiResource.put(
      '/resources/' + id,
      form
    );
    return response.data as ResourceSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ResourceSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteResource = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiResource.delete(
      '/resources/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}