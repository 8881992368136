import create from "zustand";
import { toast } from "react-toastify";
import { EventSingleResultInterface } from "../../disaster/interfaces/event-single-result.interface";
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from "../../forms/interfaces/form-input.interface";
import { generateFormLogic } from "../../../helpers/generate-form-logic";
import { EventVideoSingleResultInterface } from "../interfaces/event-video-single-result.interface";
import {
  EventVideoData,
  EventVideoResultInterface,
} from "../interfaces/event-video-result.interface";
import {
  EventVideoParam,
  GetEventVideos,
  DeleteEventVideo,
  ShowEventVideo,
  SubmitEventVideo,
  UpdateEventVideo,
} from "../providers/event-video.provider";

interface EventVideoState {
  eventVideos: EventVideoData[];
  eventVideoModel?: EventVideoResultInterface;
  eventVideoLoading: boolean;
  eventVideoSelectDatas: SelectData[];
  getEventVideos: (eventVideoParam: EventVideoParam) => void;
  deleteEventVideo: (id: string, eventVideoParam: EventVideoParam) => void;
  appendEventVideos?: (data: EventVideoData) => void;
}
const useEventVideoStore = create<EventVideoState>((set, get) => ({
  eventVideos: [],
  eventVideoSelectDatas: [],
  eventVideoModel: undefined,
  eventVideoLoading: false,
  getEventVideos: async (eventVideoParam: EventVideoParam) => {
    set({ eventVideoLoading: true });
    const result = await GetEventVideos(eventVideoParam);
    set({ eventVideoModel: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformsDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformsDatas.push({
              label: item.content,
              value: item.id,
            });
          }
          set({
            eventVideos: result.data.items,
            eventVideoSelectDatas: transformsDatas,
          });
        } else {
          set({
            eventVideos: [],
            eventVideoSelectDatas: [],
          });
        }
      } else {
        set({
          eventVideos: [],
          eventVideoSelectDatas: [],
        });
      }
    } else {
      set({
        eventVideos: [],
        eventVideoSelectDatas: [],
      });
    }
    set({ eventVideoLoading: false });
  },
  deleteEventVideo: async (id: string, param: EventVideoParam) => {
    set({ eventVideoLoading: true });
    const result = await DeleteEventVideo(id);
    if (result.status) {
      toast.success(result.message);
      get().getEventVideos(param);
    } else {
      toast.error(result.message);
    }
    set({ eventVideoLoading: false });
  },
  appendEventVideos: (data: EventVideoData) => {
    // check is data is exist
    const index = get().eventVideos.findIndex((el) => el.id === data.id);
    if (index === -1) {
      const datas = [data, ...get().eventVideos];
      set({ eventVideos: datas });
    }
  },
}));

interface EventVideoFormState {
  forms?: FormInput[];
  setForms?: (
    index: number,
    value: any,
    isVideo?: boolean,
    thumbnail?: string
  ) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: EventVideoSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const eventFormInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
};

const useEventVideoFormStore = create<EventVideoFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (
    index: number,
    value: any,
    isVideo?: boolean,
    thumbnail?: string
  ) => {
    const forms = [...get().forms];
    console.log(isVideo, thumbnail);
    if (isVideo !== undefined && isVideo === true) {
      forms[index] = {
        ...forms[index],
        value: value,
        thumbnail: thumbnail,
      };
    } else {
      forms[index] = {
        ...forms[index],
        value: value,
      };
    }
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    };
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowEventVideo(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
  },
  generateForms: (formParam: FormProps) => {
    set({ forms: [], formParam: formParam, submitDone: undefined });
    let data: EventVideoData = undefined;
    if (formParam.data !== undefined) {
      data = formParam.data as EventVideoData;
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: "Media",
        placeholder: "Choose file...",
        type: "file-upload-video-v2",
        name: "media_id",
        value: data?.media_id ?? "",
        thumbnail: data?.thumbnail ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Title",
        placeholder: "title...",
        type: "text",
        name: "title",
        value: data?.title ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Content",
        placeholder: "Content...",
        type: "text",
        name: "content",
        value: data?.content ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Latitude",
        placeholder: "Latitude...",
        type: "text",
        name: "lat",
        value: data?.lat ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Longitude",
        placeholder: "Longitude...",
        type: "text",
        name: "lng",
        value: data?.lng ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    // check required field
    let errorExist = false;
    for (const v of get().forms) {
      if (v.required === true && (v.value === undefined || v.value === "")) {
        toast.error(`Please fill ${v.title}`);
        errorExist = true;
      }
    }

    if (errorExist === true) {
      return;
    }

    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error("Cant submit form, because its read-only");
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ""),
        };
      }

      formData = {
        ...formData,
        event_id: get().formParam?.eventId,
        thumbnail: get().forms[0].thumbnail,
      };

      let res: EventSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE)
        res = await SubmitEventVideo(formData);
      if (get().formParam.action === FormAction.UPDATE)
        res = await UpdateEventVideo(get().formParam.id, formData);

      if (res.status) {
        toast.success(res.message);
        set({ forms: [], submitDone: true });
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message ?? res.error);
        }
        set({ submitDone: false });
      }
      set({ formLoading: false });
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
    }
  },
  reset: () => {
    set(eventFormInitialState);
  },
}));

export { useEventVideoStore, useEventVideoFormStore };
