import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { PotencyVideoResultInterface } from "../interfaces/potency-video-result.interface";
import { PotencyVideoSingleResultInterface } from "../interfaces/potency-video-single-result.interface";

export interface PotencyVideoParam {
  size: number;
  page: number;
}

export const GetPotencyVideos = async (
  param: PotencyVideoParam
): Promise<PotencyVideoResultInterface> => {
  try {
    const result = await ApiDisaster.get("/potency-videos", {
      params: {
        size: param.size,
        page: param.page,
      },
    });
    return result.data as PotencyVideoResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowPotencyVideo = async (
  id: string
): Promise<PotencyVideoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.get("/potency-videos/" + id);
    return response.data as PotencyVideoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: PotencyVideoSingleResultInterface = a.response?.data;
    return response;
  }
};

export const SubmitPotencyVideo = async (
  form: any
): Promise<PotencyVideoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.post("/potency-videos", form);
    return response.data as PotencyVideoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: PotencyVideoSingleResultInterface = a.response?.data;
    return response;
  }
};

export const UpdatePotencyVideo = async (
  id: string,
  form: any
): Promise<PotencyVideoSingleResultInterface> => {
  try {
    let response = await ApiDisaster.put("/potency-videos/" + id, form);
    return response.data as PotencyVideoSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: PotencyVideoSingleResultInterface = a.response?.data;
    return response;
  }
};

export const DeletePotencyVideo = async (
  id: string
): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.delete("/potency-videos/" + id);
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};
