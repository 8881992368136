import { AxiosError } from "axios";
import { ApiAuthorization } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { UserResultInterface } from "../interfaces/user-result.interface";
import { UserSingleResultInterface } from "../interfaces/user-single-result.interface";

export interface UserParam {
  size: number;
  page: number;
  name?: string;
}

export const GetUsers = async (
  param: UserParam
): Promise<UserResultInterface> => {
  try {
    const result = await ApiAuthorization.get("/users", {
      params: param,
    });
    return result.data as UserResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const GetUsersVolunteer = async (
  param: UserParam
): Promise<UserResultInterface> => {
  try {
    const result = await ApiAuthorization.get("/users_volunteer", {
      params: param,
    });
    return result.data as UserResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const GetTotalVolunteer = async (): Promise<GeneralInterface> => {
  try {
    const result = await ApiAuthorization.get("/total_users_volunteer");
    return result.data as GeneralInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowUser = async (
  id: string
): Promise<UserSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.get("/users/" + id);
    return response.data as UserSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: UserSingleResultInterface = a.response?.data;
    return response;
  }
};

export const SubmitUser = async (
  form: any
): Promise<UserSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.post("/users", form);
    return response.data as UserSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: UserSingleResultInterface = a.response?.data;
    return response;
  }
};

export const UpdateUser = async (
  id: string,
  form: any
): Promise<UserSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.put("/users/" + id, form);
    return response.data as UserSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: UserSingleResultInterface = a.response?.data;
    return response;
  }
};

export const DeleteUser = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorization.delete("/users/" + id);
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};
