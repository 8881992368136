import { useEffect, useState } from "react";
import { Modal, Button, Table, ButtonGroup } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import FormsComponent from "../../forms/components/forms.component";
import { Input } from "../../forms/components/input.component";
import { FormAction } from "../../forms/interfaces/form-input.interface";
import PageHeaderComponent from "../../home/components/page-header.component";
import { useRoleStore } from "../../roles/states/role.state";
import { UserData } from "../interfaces/user-result.interface";
import { useUserFormStore, useUserStore } from "../states/user.state";

const UserScreen = () => {
  const { getUsers, users, userLoading, userModel, deleteUser } =
    useUserStore();
  const {
    submitDone,
    formLoading,
    forms,
    setForms,
    generateForms,
    setFormSelectData,
    onSubmitForm,
    formParam,
  } = useUserFormStore();
  const { roleSelectDatas, getRoles } = useRoleStore();
  const [show, setShow] = useState<boolean>(false);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    getRoles({
      size: 100,
      page: 0,
    });
    setShow(true);
  };

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: UserData
  ) => {
    generateForms({
      action: FormAction.UPDATE,
      title: "User",
      subtitle: "Ubah User",
      id: item.id,
      data: item,
    });
    handleShow();
  };

  const handleView = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: UserData
  ) => {
    generateForms({
      action: FormAction.VIEW,
      title: "User",
      subtitle: "Lihat User",
      id: item.id,
      data: item,
    });
    handleShow();
  };

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  useEffect(() => {
    getUsers({
      size: size,
      page: page,
      name: search,
    });
  }, [page]);

  useEffect(() => {
    console.log(forms);
  }, [forms]);

  useEffect(() => {
    if (roleSelectDatas.length > 0) {
      setFormSelectData(5, roleSelectDatas);
    }
  }, [roleSelectDatas]);

  useEffect(() => {
    if (submitDone !== undefined) {
      if (submitDone) {
        handleClose();
        generateForms({
          action: FormAction.CREATE,
          title: "User",
          subtitle: "Tambah User",
        });
        getUsers({
          size: 20,
          page: 0,
          name: search,
        });
      }
    }
  }, [submitDone]);

  return (
    <>
      <PageHeaderComponent
        title="User"
        subtitle="Daftar User"
        hasButton={true}
        buttonTitle="Tambah"
        onClick={(e) => {
          generateForms({
            action: FormAction.CREATE,
            title: "User",
            subtitle: "Tambah User",
            data: undefined,
            id: undefined,
          });
          handleShow();
        }}
      />
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getUsers({
                    size: 20,
                    page: 0,
                    name: search,
                  });
                }}
              >
                <Input
                  title={"Search"}
                  name={"search"}
                  type={"text"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button
                  variant="primary"
                  style={{
                    marginLeft: 10,
                    marginTop: 10,
                  }}
                  onClick={(e) => {
                    getUsers({
                      size: 20,
                      page: 0,
                      name: search,
                    });
                  }}
                >
                  Search
                </Button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="wd-1p">No</th>
                    <th className="wd-15p">Nama</th>
                    <th className="wd-15p">Email</th>
                    <th className="wd-15p">No. HP</th>
                    <th className="wd-15p">Role</th>
                    <th className="wd-3p" style={{ width: 50 }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userLoading && (
                    <tr>
                      <td colSpan={7}>Loading</td>
                    </tr>
                  )}
                  {users.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.fullName}</td>
                        <td>{item.email}</td>
                        <td>{item.phone_number}</td>
                        <td>{item.role?.name}</td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="primary"
                              onClick={(e) => handleView(e, item)}
                            >
                              Lihat
                            </Button>
                            <Button
                              variant="warning"
                              onClick={(e) => handleEdit(e, item)}
                            >
                              Ubah
                            </Button>
                            <Button
                              variant="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                const response = window.confirm(
                                  "Apakah yakin ingin menghapus data ini ?"
                                );
                                if (response === true) {
                                  deleteUser(item.id);
                                }
                              }}
                            >
                              Hapus
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="card-footer">
              <ReactPaginate
                previousLabel={"«"}
                nextLabel={"»"}
                breakLabel={"..."}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                pageCount={userModel?.data?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-sm m-0 float-right"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size={"lg"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitForm();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{formParam?.subtitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormsComponent forms={forms} setForms={setForms} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => {
                if (!formLoading) handleClose();
              }}
              className={formLoading && "btn-loading"}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className={formLoading && "btn-loading"}
              onClick={(e) => {
                e.preventDefault();
                if (!formLoading) onSubmitForm();
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UserScreen;
