import create from "zustand";
import { toast } from "react-toastify";
import { EventSingleResultInterface } from "../../disaster/interfaces/event-single-result.interface";
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from "../../forms/interfaces/form-input.interface";
import { generateFormLogic } from "../../../helpers/generate-form-logic";
import { EventPhotoSingleResultInterface } from "../interfaces/event-photo-single-result.interface";
import {
  EventPhotoData,
  EventPhotoResultInterface,
} from "../interfaces/event-photo-result.interface";
import {
  EventPhotoParam,
  GetEventPhotos,
  DeleteEventPhoto,
  ShowEventPhoto,
  SubmitEventPhoto,
  UpdateEventPhoto,
} from "../providers/event-photo.provider";

interface EventPhotoState {
  eventPhotos: EventPhotoData[];
  eventPhotoModel?: EventPhotoResultInterface;
  eventPhotoLoading: boolean;
  eventPhotoSelectDatas: SelectData[];
  getEventPhotos: (eventPhotoParam: EventPhotoParam) => void;
  deleteEventPhoto: (id: string, eventPhotoParam: EventPhotoParam) => void;
  appendEventPhotos?: (data: EventPhotoData) => void;
}
const useEventPhotoStore = create<EventPhotoState>((set, get) => ({
  eventPhotos: [],
  eventPhotoSelectDatas: [],
  eventPhotoModel: undefined,
  eventPhotoLoading: false,
  getEventPhotos: async (eventPhotoParam: EventPhotoParam) => {
    set({ eventPhotoLoading: true });
    const result = await GetEventPhotos(eventPhotoParam);
    set({ eventPhotoModel: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformsDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformsDatas.push({
              label: item.content,
              value: item.id,
            });
          }
          set({
            eventPhotos: result.data.items,
            eventPhotoSelectDatas: transformsDatas,
          });
        } else {
          set({
            eventPhotos: [],
            eventPhotoSelectDatas: [],
          });
        }
      } else {
        set({
          eventPhotos: [],
          eventPhotoSelectDatas: [],
        });
      }
    } else {
      set({
        eventPhotos: [],
        eventPhotoSelectDatas: [],
      });
    }
    set({ eventPhotoLoading: false });
  },
  deleteEventPhoto: async (id: string, param: EventPhotoParam) => {
    set({ eventPhotoLoading: true });
    const result = await DeleteEventPhoto(id);
    if (result.status) {
      toast.success(result.message);
      get().getEventPhotos(param);
    } else {
      toast.error(result.message);
    }
    set({ eventPhotoLoading: false });
  },
  appendEventPhotos: (data: EventPhotoData) => {
    // check is data is exist
    const index = get().eventPhotos.findIndex((el) => el.id === data.id);
    if (index === -1) {
      const datas = [data, ...get().eventPhotos];
      set({ eventPhotos: datas });
    }
  },
}));

interface EventPhotoFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: EventPhotoSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const eventFormInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
};

const useEventPhotoFormStore = create<EventPhotoFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    };
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    };
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowEventPhoto(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
  },
  generateForms: (formParam: FormProps) => {
    set({ forms: [], formParam: formParam, submitDone: undefined });
    let data: EventPhotoData = undefined;
    if (formParam.data !== undefined) {
      data = formParam.data as EventPhotoData;
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: "Media",
        placeholder: "Choose file...",
        type: "file-upload-v2",
        name: "media_id",
        value: data?.media_id,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Title",
        placeholder: "title...",
        type: "text",
        name: "title",
        value: data?.title ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Content",
        placeholder: "Content...",
        type: "text",
        name: "content",
        value: data?.content ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Latitude",
        placeholder: "Latitude...",
        type: "text",
        name: "lat",
        value: data?.lat ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Longitude",
        placeholder: "Longitude...",
        type: "text",
        name: "lng",
        value: data?.lng ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    // check required field
    let errorExist = false;
    for (const v of get().forms) {
      if (v.required === true && (v.value === undefined || v.value === "")) {
        toast.error(`Please fill ${v.title}`);
        errorExist = true;
      }
    }

    if (errorExist === true) {
      return;
    }

    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error("Cant submit form, because its read-only");
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ""),
        };
      }

      formData = {
        ...formData,
        event_id: get().formParam?.eventId,
      };

      let res: EventSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE)
        res = await SubmitEventPhoto(formData);
      if (get().formParam.action === FormAction.UPDATE)
        res = await UpdateEventPhoto(get().formParam.id, formData);

      if (res.status) {
        toast.success(res.message);
        set({ forms: [], submitDone: true });
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message ?? res.error);
        }
        set({ submitDone: false });
      }
      set({ formLoading: false });
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
    }
  },
  reset: () => {
    set(eventFormInitialState);
  },
}));

export { useEventPhotoStore, useEventPhotoFormStore };
