import create from "zustand";
import { toast } from "react-toastify";
import { EventSingleResultInterface } from "../../disaster/interfaces/event-single-result.interface";
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from "../../forms/interfaces/form-input.interface";
import { generateFormLogic } from "../../../helpers/generate-form-logic";
import {
  EventGeneralInfoParam,
  GetEventGeneralInfos,
  DeleteEventGeneralInfo,
  ShowEventGeneralInfo,
  SubmitEventGeneralInfo,
  UpdateEventGeneralInfo,
} from "../providers/event-general-info.provider";
import {
  EventGeneralInfoData,
  EventGeneralInfoResultInterface,
} from "../interfaces/event-general-info-result.interface";
import { EventGeneralInfoSingleResultInterface } from "../interfaces/event-general-info-single-result.interface";

interface EventGeneralInfoState {
  eventGeneralInfos: EventGeneralInfoData[];
  eventGeneralInfoModel?: EventGeneralInfoResultInterface;
  eventGeneralInfoLoading: boolean;
  eventGeneralInfoSelectDatas: SelectData[];
  getEventGeneralInfos: (eventGeneralInfoParam: EventGeneralInfoParam) => void;
  deleteEventGeneralInfo: (
    id: string,
    eventGeneralInfoParam: EventGeneralInfoParam
  ) => void;
}
const useEventGeneralInfoStore = create<EventGeneralInfoState>((set, get) => ({
  eventGeneralInfos: [],
  eventGeneralInfoSelectDatas: [],
  eventGeneralInfoModel: undefined,
  eventGeneralInfoLoading: false,
  getEventGeneralInfos: async (
    eventGeneralInfoParam: EventGeneralInfoParam
  ) => {
    set({ eventGeneralInfoLoading: true });
    const result = await GetEventGeneralInfos(eventGeneralInfoParam);
    set({ eventGeneralInfoModel: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformsDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformsDatas.push({
              label: item.content,
              value: item.id,
            });
          }
          set({
            eventGeneralInfos: result.data.items,
            eventGeneralInfoSelectDatas: transformsDatas,
          });
        } else {
          set({
            eventGeneralInfos: [],
            eventGeneralInfoSelectDatas: [],
          });
        }
      } else {
        set({
          eventGeneralInfos: [],
          eventGeneralInfoSelectDatas: [],
        });
      }
    } else {
      set({
        eventGeneralInfos: [],
        eventGeneralInfoSelectDatas: [],
      });
    }
    set({ eventGeneralInfoLoading: false });
  },
  deleteEventGeneralInfo: async (id: string, param: EventGeneralInfoParam) => {
    set({ eventGeneralInfoLoading: true });
    const result = await DeleteEventGeneralInfo(id);
    if (result.status) {
      toast.success(result.message);
      get().getEventGeneralInfos(param);
    } else {
      toast.error(result.message);
    }
    set({ eventGeneralInfoLoading: false });
  },
}));

interface EventGeneralInfoFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: EventGeneralInfoSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const eventFormInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
};

const useEventGeneralInfoFormStore = create<EventGeneralInfoFormState>(
  (set, get) => ({
    loading: false,
    forms: [],
    data: undefined,
    formParam: undefined,
    setForms: (index: number, value: any) => {
      const forms = [...get().forms];
      forms[index] = {
        ...forms[index],
        value: value,
      };
      set({ forms: forms });
    },
    setFormSelectData: (index: number, selectData: SelectData[]) => {
      const forms = [...get().forms];
      forms[index] = {
        ...forms[index],
        selectData: selectData,
      };
      set({ forms: forms });
    },
    setFormParam: (formParam: FormProps) => {
      set({ formParam: formParam });
    },
    fetchSingleData: async (id: string) => {
      set({ loading: true });
      if (id !== undefined || id !== null) {
        const response = await ShowEventGeneralInfo(id);
        set({ data: response });
      } else {
        set({ data: undefined });
      }
      set({ loading: false });
    },
    generateForms: (formParam: FormProps) => {
      set({ forms: [], formParam: formParam, submitDone: undefined });
      let data: EventGeneralInfoData = undefined;
      if (formParam.data !== undefined) {
        data = formParam.data as EventGeneralInfoData;
      }
      let forms: FormInput[] = [];

      if (formParam.code === 10 || formParam.code === 11) {
        forms = [
          {
            title: "Judul",
            placeholder: "Judul...",
            type: "text",
            name: "title",
            selectData: [],
            value: data?.title,
            disabled: formParam.action === FormAction.VIEW ? true : false,
          },
          {
            title: "Sumber",
            placeholder: "Sumber...",
            type: "select-with-text",
            name: "info_source_id",
            selectData: [],
            value: {
              label: data?.info_source?.name,
              value: data?.info_source?.id,
            },
            disabled: formParam.action === FormAction.VIEW ? true : false,
          },
          {
            title: "Youtube",
            placeholder: "https://youtu.be",
            type: "text",
            name: "content",
            value: data?.content,
            disabled: formParam.action === FormAction.VIEW ? true : false,
          },
        ];
      } else {
        forms = [
          {
            title: "Judul",
            placeholder: "Judul...",
            type: "text",
            name: "title",
            selectData: [],
            value: data?.title,
            disabled: formParam.action === FormAction.VIEW ? true : false,
          },
          {
            title: "Sumber",
            placeholder: "Sumber...",
            type: "select-with-text",
            name: "info_source_id",
            selectData: [],
            value: {
              label: data?.info_source?.name,
              value: data?.info_source?.id,
            },
            disabled: formParam.action === FormAction.VIEW ? true : false,
          },
          {
            title: "PDF",
            placeholder: "Choose file...",
            type: "file-upload-v2",
            name: "content",
            value: data?.content,
            disabled: formParam.action === FormAction.VIEW ? true : false,
          },
        ];
      }

      set({ forms: forms });
    },
    onSubmitForm: async () => {
      // check required field
      let errorExist = false;
      for (const v of get().forms) {
        if (v.required === true && (v.value === undefined || v.value === "")) {
          toast.error(`Please fill ${v.title}`);
          errorExist = true;
        }
      }

      if (errorExist === true) {
        return;
      }

      try {
        set({ formLoading: true, submitDone: false });
        if (get().formParam.action === FormAction.VIEW) {
          set({ formLoading: false, submitDone: false });
          return toast.error("Cant submit form, because its read-only");
        }

        set({ loading: true });

        let formData = {};
        for (const v of get().forms) {
          formData = {
            ...formData,
            [v.name]: generateFormLogic(v.type, v?.value ?? ""),
          };
        }

        formData = {
          ...formData,
          code: get().formParam?.code?.toString(),
          event_id: get().formParam?.eventId,
        };

        let res: EventSingleResultInterface = {};

        if (get().formParam.action === FormAction.CREATE)
          res = await SubmitEventGeneralInfo(formData);
        if (get().formParam.action === FormAction.UPDATE)
          res = await UpdateEventGeneralInfo(get().formParam.id, formData);

        if (res.status) {
          toast.success(res.message);
          set({ forms: [], submitDone: true });
        } else {
          if (Array.isArray(res.message)) {
            for (const error of res.message) {
              toast.error(error);
            }
          } else {
            toast.error(res.message ?? res.error);
          }
          set({ submitDone: false });
        }
        set({ formLoading: false });
      } catch (e) {
        console.log(e);
        toast.error(e);
        set({ formLoading: false, submitDone: false });
      }
    },
    reset: () => {
      set(eventFormInitialState);
    },
  })
);

export { useEventGeneralInfoStore, useEventGeneralInfoFormStore };
