import {
  UserOrganizationData,
  UserOrganizationResultInterface,
} from "../interfaces/user-organization-result.interface";
import create from "zustand";
import {
  DeleteUserOrganization,
  GetUserOrganizations,
  UserOrganizationParam,
  ShowUserOrganization,
  SubmitUserOrganization,
  UpdateUserOrganization,
} from "../providers/user-organization.provider";
import { toast } from "react-toastify";
import { EventSingleResultInterface } from "../../disaster/interfaces/event-single-result.interface";
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from "../../forms/interfaces/form-input.interface";
import { UserOrganizationSingleResultInterface } from "../interfaces/user-organization-single-result.interface";
import { generateFormLogic } from "../../../helpers/generate-form-logic";

interface UserOrganizationState {
  userOrganizations: UserOrganizationData[];
  userOrganizationModel?: UserOrganizationResultInterface;
  userOrganizationLoading: boolean;
  userOrganizationSelectDatas: SelectData[];
  getUserOrganizations: (userOrganizationParam: UserOrganizationParam) => void;
  deleteUserOrganization: (id: string) => void;
}
const useUserOrganizationStore = create<UserOrganizationState>((set, get) => ({
  userOrganizations: [],
  userOrganizationSelectDatas: [],
  userOrganizationModel: undefined,
  userOrganizationLoading: false,
  getUserOrganizations: async (
    userOrganizationParam: UserOrganizationParam
  ) => {
    set({ userOrganizationLoading: true });
    const result = await GetUserOrganizations(userOrganizationParam);
    set({ userOrganizationModel: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformsDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformsDatas.push({
              label: item.name,
              value: item.id,
            });
          }
          set({
            userOrganizations: result.data.items,
            userOrganizationSelectDatas: transformsDatas,
          });
        } else {
          set({
            userOrganizations: [],
            userOrganizationSelectDatas: [],
          });
        }
      } else {
        set({
          userOrganizations: [],
          userOrganizationSelectDatas: [],
        });
      }
    } else {
      set({
        userOrganizations: [],
        userOrganizationSelectDatas: [],
      });
    }
    set({ userOrganizationLoading: false });
  },
  deleteUserOrganization: async (id: string) => {
    set({ userOrganizationLoading: true });
    const result = await DeleteUserOrganization(id);
    if (result.status) {
      toast.success(result.message);
      get().getUserOrganizations({ size: 20, page: 0 });
    } else {
      toast.error(result.message);
    }
    set({ userOrganizationLoading: false });
  },
}));

interface UserOrganizationFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: UserOrganizationSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const eventFormInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
};

const useUserOrganizationFormStore = create<UserOrganizationFormState>(
  (set, get) => ({
    loading: false,
    forms: [],
    data: undefined,
    formParam: undefined,
    setForms: (index: number, value: any) => {
      const forms = [...get().forms];
      forms[index] = {
        ...forms[index],
        value: value,
      };
      set({ forms: forms });
    },
    setFormSelectData: (index: number, selectData: SelectData[]) => {
      const forms = [...get().forms];
      forms[index] = {
        ...forms[index],
        selectData: selectData,
      };
      console.log("asdasd_asd", forms[index]);
      set({ forms: forms });
    },
    setFormParam: (formParam: FormProps) => {
      set({ formParam: formParam });
    },
    fetchSingleData: async (id: string) => {
      set({ loading: true });
      if (id !== undefined || id !== null) {
        const response = await ShowUserOrganization(id);
        set({ data: response });
      } else {
        set({ data: undefined });
      }
      set({ loading: false });
    },
    generateForms: (formParam: FormProps) => {
      set({ forms: [], formParam: formParam, submitDone: undefined });
      let data: UserOrganizationData = undefined;
      if (formParam.data !== undefined) {
        data = formParam.data as UserOrganizationData;
      }
      let forms: FormInput[] = [];

      forms = [
        {
          title: "Name",
          placeholder: "Name...",
          type: "text",
          name: "name",
          value: data?.name ?? "",
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        },
      ];
      set({ forms: forms });
    },
    onSubmitForm: async () => {
      // check required field
      let errorExist = false;
      for (const v of get().forms) {
        if (v.required === true && (v.value === undefined || v.value === "")) {
          toast.error(`Please fill ${v.title}`);
          errorExist = true;
        }
      }

      if (errorExist === true) {
        return;
      }

      try {
        set({ formLoading: true, submitDone: false });
        if (get().formParam.action === FormAction.VIEW) {
          set({ formLoading: false, submitDone: false });
          return toast.error("Cant submit form, because its read-only");
        }

        set({ loading: true });

        let formData = {};
        for (const v of get().forms) {
          formData = {
            ...formData,
            [v.name]: generateFormLogic(v.type, v?.value ?? ""),
          };
        }

        let res: EventSingleResultInterface = {};

        if (get().formParam.action === FormAction.CREATE)
          res = await SubmitUserOrganization(formData);
        if (get().formParam.action === FormAction.UPDATE)
          res = await UpdateUserOrganization(get().formParam.id, formData);

        if (res.status) {
          toast.success(res.message);
          set({ forms: [], submitDone: true });
        } else {
          if (Array.isArray(res.message)) {
            for (const error of res.message) {
              toast.error(error);
            }
          } else {
            toast.error(res.message ?? res.error);
          }
          set({ submitDone: false });
        }
        set({ formLoading: false });
      } catch (e) {
        console.log(e);
        toast.error(e);
        set({ formLoading: false, submitDone: false });
      }
    },
    reset: () => {
      set(eventFormInitialState);
    },
  })
);

export { useUserOrganizationStore, useUserOrganizationFormStore };
