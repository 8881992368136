import { AxiosError } from "axios";
import { ApiReport } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { ReportResultInterface } from "../interfaces/report-result.interface";
import { ReportSingleResultInterface } from "../interfaces/report-single-result.interface";

export interface ReportParam {
  size: number;
  page: number;
  event_id: string;
}

export const GetReports = async (param: ReportParam): Promise<ReportResultInterface> => {

  console.log("asdasd", param)
  try {
    const result = await ApiReport.get("/reports", {
      params: param
    });
    return result.data as ReportResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowReport = async (id: string): Promise<ReportSingleResultInterface> => {
  try {
    let response = await ApiReport.get(
      '/reports/' + id,
    );
    return response.data as ReportSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ReportSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitReport = async (form: any): Promise<ReportSingleResultInterface> => {
  try {
    let response = await ApiReport.post(
      '/reports',
      form
    );
    return response.data as ReportSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ReportSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateReport = async (id: string, form: any): Promise<ReportSingleResultInterface> => {
  try {
    let response = await ApiReport.put(
      '/reports/' + id,
      form
    );
    return response.data as ReportSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: ReportSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteReport = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiReport.delete(
      '/reports/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}