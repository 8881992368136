import { toast } from "react-toastify";
import create from "zustand";
import { SelectData } from "../../forms/interfaces/form-input.interface";
import { InfoSourceData, InfoSourceResultInterface } from "../interfaces/info-source-result.interface";
import { InfoSourceParam, GetInfoSources, DeleteInfoSource } from "../providers/info-source.provider";

interface InfoSourceState {
  infoSources: InfoSourceData[];
  infoSourceModel?: InfoSourceResultInterface;
  infoSourceLoading: boolean;
  infoSourceSelectDatas: SelectData[];
  getInfoSources: (infoSourceParam: InfoSourceParam) => void;
  deleteInfoSource: (id: string, infoSourceParam: InfoSourceParam) => void;
}
const useInfoSourceStore = create<InfoSourceState>((set, get) => ({
  infoSources: [],
  infoSourceSelectDatas: [],
  infoSourceModel: undefined,
  infoSourceLoading: false,
  getInfoSources: async (infoSourceParam: InfoSourceParam) => {
    set({ infoSourceLoading: true });
    const result = await GetInfoSources(infoSourceParam);
    set({ infoSourceModel: result })
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformsDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformsDatas.push({
              label: item.name,
              value: item.id
            });
          }
          set({ 
            infoSources: result.data.items,
            infoSourceSelectDatas: transformsDatas 
          });
        } else {
          set({ 
            infoSources: [],
            infoSourceSelectDatas: [] 
          });
        }
      } else {
        set({ 
          infoSources: [],
          infoSourceSelectDatas: [] 
        });
      }
    } else {
      set({ 
        infoSources: [],
        infoSourceSelectDatas: [] 
      });
    }
    set({ infoSourceLoading: false })
  },
  deleteInfoSource: async (id: string, param: InfoSourceParam) => {
    set({ infoSourceLoading: true });
    const result = await DeleteInfoSource(id);
    if (result.status) {
      toast.success(result.message)
      get().getInfoSources(param)
    } else {
      toast.error(result.message)
    }
    set({ infoSourceLoading: false });
  }
}))

export {
  useInfoSourceStore
}