import { Link } from "react-router-dom";

interface NavbarComponentProps {
  show: boolean;
}

const NavbarComponent: React.FC<NavbarComponentProps> = ({ show }) => {
  return (
    <div
      className={`admin-navbar ${show && "collapse show"}`}
      id="headerMenuCollapse"
    >
      <div className="container">
        <ul className="nav">
          <li className="nav-item">
            <Link
              type="a"
              to={"/home/dashboard"}
              className="nav-link"
              id="navHome"
            >
              <i className="fa fa-home" />
              <span> BERANDA</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              type="a"
              to={"/home/disaster"}
              className="nav-link"
              id="navHome"
            >
              <i className="fa fa-home" />
              <span> Bencana</span>
            </Link>
          </li>
          <li className="nav-item with-sub">
            <Link type="a" className="nav-link" to={"/"}>
              <i className="fa fa-group" /> <span>User Management</span>
            </Link>
            <div className="sub-item">
              <ul>
                <li>
                  <Link type="a" to={"/home/role"}>
                    Role
                  </Link>
                </li>
                <li>
                  <Link type="a" to={"/home/user"}>
                    User
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item with-sub">
            <Link type="a" className="nav-link" to={"/"}>
              <i className="fa fa-group" /> <span>Relawan</span>
            </Link>
            <div className="sub-item">
              <ul>
                <li>
                  <Link type="a" to={"/home/user-volunteer"}>
                    Personil
                  </Link>
                </li>
                <li>
                  <Link type="a" to={"/home/user-organizations"}>
                    Organisasi
                  </Link>
                </li>
                <li>
                  <a href="/home/assignment">Penugasan </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item with-sub">
            <a className="nav-link" id="navPersonal" href="/">
              <i className="fa fa-camera" /> <span>Asesmen</span>
            </a>
            <div className="sub-item">
              <ul>
                <li>
                  <Link type="a" to={"/home/photo"}>
                    Foto
                  </Link>
                </li>
                <li>
                  <Link type="a" to={"/home/video"}>
                    Video
                  </Link>
                </li>
                {/* <li>
                  <a href="/post">Posko</a>
                </li>
                <li>
                  <a href="/refugee">Pengungsi</a>
                </li> */}
              </ul>
            </div>
          </li>
          <li className="nav-item with-sub">
            <a className="nav-link" id="navBook" href="/">
              <i className="fa fa-book" /> <span>Informasi</span>
            </a>
            <div className="sub-item">
              <ul>
                <li>
                  <Link type="a" to={"/home/tv"}>
                    Berita TV
                  </Link>
                </li>
                <li>
                  <Link type="a" to={"/home/general"}>
                    Informasi Umum
                  </Link>
                </li>
                <li>
                  <Link type="a" to={"/home/live"}>
                    Siaran Langsung
                  </Link>
                </li>
                {/* <li>
                  <a href="/map">Gambar Peta </a>
                </li> */}
                <li>
                  <a href="/home/report">Laporan</a>
                </li>
                <li>
                  <Link type="a" to={"/home/potency-photo"}>
                    Foto Potensi
                  </Link>
                </li>
                <li>
                  <Link type="a" to={"/home/potency-video"}>
                    Video Potensi
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item with-sub">
            <a href="/" className="nav-link" id="navResource">
              <i className="fa fa-cubes" /> <span>Sumber Daya</span>
            </a>
            <div className="sub-item">
              <ul>
                <li>
                  <Link type="a" to={"/home/resource"}>
                    Sumber Daya
                  </Link>
                </li>
                <li>
                  <Link type="a" to={"/home/items"}>
                    Item
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavbarComponent;
