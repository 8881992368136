import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosAssignmentInterceptorsSetup, AxiosAuthorizationInterceptorsSetup, AxiosDisasterInterceptorsSetup, AxiosReportInterceptorsSetup, AxiosResourceInterceptorsSetup } from "../../../api/api";
import { useAuthStore } from "../states/auth.state";

const AxiosInterceptorNavigate = () => {
  const navigate = useNavigate();
  const uas = useAuthStore(); 
  useEffect(() => {
    AxiosDisasterInterceptorsSetup(navigate, uas);
    AxiosAuthorizationInterceptorsSetup(navigate, uas);
    AxiosAssignmentInterceptorsSetup(navigate, uas);
    AxiosResourceInterceptorsSetup(navigate, uas);
    AxiosReportInterceptorsSetup(navigate, uas);
  }, []);
  return <></>;
}

export default AxiosInterceptorNavigate;