import { useLocation, useNavigate } from "react-router-dom";
import FormsComponent from "../../forms/components/forms.component";
import PageHeaderComponent from "../../home/components/page-header.component";
import { useUserOrganizationStore } from "../../organizations/states/user-organization.state";
import { useUserDetailFormStore } from "../states/user-detail.state";
import { useEffect, useState } from "react";
import { UserData } from "../interfaces/user-result.interface";
import { FormAction } from "../../forms/interfaces/form-input.interface";
import { Button } from "react-bootstrap";
import { useCityStore } from "../../disaster/states/city.state";
import { useProvinceStore } from "../../disaster/states/province.state";

interface UserDetailFormComponentProps {
  userDetailId: string;
  userId: string;
}

const UserDetailFormComponent = () => {
  const {
    submitDone,
    formLoading,
    forms,
    setForms,
    generateForms,
    setFormSelectData,
    onSubmitForm,
    formParam,
    fetchSingleData,
  } = useUserDetailFormStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<UserData>(undefined);
  const { citySelectDatas, getCitys } = useCityStore();
  const { provinceSelectDatas, getProvinces, provinceDatas } =
    useProvinceStore();

  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {
    console.log(location?.state);
    if (location) {
      setUser(location?.state as UserData);
      if ((location?.state as UserData)?.user_detail_id !== undefined) {
        fetchSingleData((location?.state as UserData)?.user_detail_id);
      } else {
        generateForms({
          action: FormAction.CREATE,
          title: "User Detail",
          subtitle: "Create User Detail",
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (submitDone !== undefined) {
      if (submitDone) {
        navigate(-1);
      }
    }
  }, [submitDone]);

  useEffect(() => {
    if (citySelectDatas.length > 0) {
      if (forms.length > 0) {
        setFormSelectData(10, citySelectDatas);
      }
    }
  }, [citySelectDatas]);

  return (
    <>
      <PageHeaderComponent
        title="Relawan"
        subtitle="Daftar Relawan"
        hasButton={false}
        buttonTitle="Tambah"
      />
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <FormsComponent
                setForms={setForms}
                forms={forms}
                selectWithTextCb={(index, name, value) => {
                  if (index === 9) {
                    const province = provinceDatas?.find(
                      (el) => el.id === value
                    );
                    if (province) {
                      getCitys(province?.prov_code);
                    }
                  }
                }}
              />
            </div>
            <div className="card-footer">
              <Button
                variant="secondary"
                onClick={(e) => {
                  if (!formLoading) navigate(-1);
                }}
                className={formLoading && "btn-loading"}
              >
                Close
              </Button>
              <Button
                variant="primary"
                className={formLoading && "btn-loading"}
                onClick={(e) => {
                  e.preventDefault();
                  if (!formLoading) onSubmitForm(user?.id);
                }}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetailFormComponent;
