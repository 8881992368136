import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../states/auth.state";
import io from "socket.io-client";

function LoginScreen() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { login, loading, isLoggedIn } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home");
    }
  }, [isLoggedIn]);

  return (
    <div
      className="login-img"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className="col col-login mx-auto">
                <div className="text-center mb-6"></div>
                <form
                  className="card"
                  onSubmit={(e) => {
                    e.preventDefault();
                    login(username, password);
                  }}
                >
                  <div className="card-body p-6">
                    <div className="card-title text-center">
                      Administrasi TERRA
                    </div>
                    <div className="form-group">
                      <label className="form-label">Nama</label>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        id="loginUsername"
                        placeholder="Masukan Nama"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Kata Kunci</label>
                      <input
                        type="password"
                        className="form-control"
                        id="loginPassword"
                        placeholder="Masukan Kata Kunci"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-footer">
                      <button
                        type="submit"
                        className={`btn btn-primary btn-block ${
                          loading ? "btn-loading" : ""
                        }`}
                        id="loginBtn"
                        onClick={(e) => {
                          e.preventDefault();
                          login(username, password);
                          // socket.emit("sendMessage", username);
                        }}
                      >
                        Masuk
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;
