import {
  CityData,
  CityResultInterface,
} from "../interfaces/city-result.interface";
import create from "zustand";
import { CityParam, GetCitys } from "../providers/city.provider";
import { SelectData } from "../../forms/interfaces/form-input.interface";

interface DisasterState {
  cityDatas: CityData[];
  citySelectDatas: SelectData[];
  result?: CityResultInterface;
  param: CityParam;
  loading: boolean;
  getCitys: (provCode: string) => void;
}

const useCityStore = create<DisasterState>((set, get) => ({
  cityDatas: [],
  citySelectDatas: [],
  result: undefined,
  loading: false,
  param: {
    size: 100000,
    page: 0,
    provCode: "",
  },
  getCitys: async (provCode: string) => {
    set({ loading: true });
    let params: CityParam = {
      size: 1000,
      page: 0,
      provCode: provCode,
      // provCode: undefined,
    };
    const result = await GetCitys(params);
    set({ result: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformDatas.push({
              label: item.name,
              value: item.id,
            });
          }
          set({
            cityDatas: result.data.items,
            citySelectDatas: transformDatas,
          });
        } else {
          set({
            cityDatas: [],
            citySelectDatas: [],
          });
        }
      } else {
        set({
          cityDatas: [],
          citySelectDatas: [],
        });
      }
    } else {
      set({
        cityDatas: [],
        citySelectDatas: [],
      });
    }
    set({ loading: false });
  },
}));

export { useCityStore };
