import { useState, useEffect } from "react";
import { generateImageUrl } from "../../../helpers/generate-image-url";
import { useDashboardStore } from "../../dashboard/states/dashboard.state";
import { useEventPhotoStore } from "../../event-photos/states/event-photo.state";
import RichTextV2 from "../../forms/components/ckeditor/rich-text-v2";
import PageHeaderComponent from "../../home/components/page-header.component";
import { useReportFormStore, useReportStore } from "../states/report.state";
import styles from './report-form.module.css';

const ReportFormScreen = () => {
  // const [data, setData] = useState<string>("");
  const [ editor, setEditor ] = useState<any>();
  const { getEventPhotos, eventPhotos } = useEventPhotoStore();
  const { selectedReport, setSelectedReport } = useReportStore();
  const { selectedDisaster } = useDashboardStore();
  const { setDescription, description, onSubmitFormV2 } = useReportFormStore();

  useEffect(() => {
    return () => {
      setSelectedReport(undefined);
    }
  }, [])

  useEffect(() => {
    if (selectedDisaster !== undefined) {
      getEventPhotos({
        size: 10,
        page: 0,
        event_id: selectedDisaster?.value
      })
    }
  }, [selectedDisaster])

  useEffect(() => {
    if (selectedReport === undefined) {
      setDescription(`
        <p>
          <img class="image_resized" style="width:123px;" src="https://storage.app-terra.net/storage/1642870719283--image.png"> 
          <img class="image_resized image-style-align-right" style="width:218px;" src="https://storage.app-terra.net/storage/1642870719239--image.png">
        </p>
        <figure class="table">
          <table>
            <tbody>
              <tr>
                <td>Nama Kejadian</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Kronologis / Latar Belakang Kejadian</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Lokasi</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Korban</td>
                <td>
                  <ul>
                    <li style="text-align:justify;">&nbsp;</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Kerugian Materiil</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Kondisi Sinyal Telkomsel</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Penanganan</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Pembuat Laporan</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Tanggal / Jam</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </figure>
        <p>&nbsp;</p>
        <br/>
      `);
    } else {
      setDescription(selectedReport?.description);
    }
  }, [selectedReport])
  
  return (
    <>
      <PageHeaderComponent
        title="Report Form"
        subtitle="Report Form"
        hasButton={true}
        buttonTitle="Save Report"
        onClick={(e) => {
          e.preventDefault();
          onSubmitFormV2(selectedDisaster?.value);
        }}
      />
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12 col-md-12" style={{ marginBottom: 5}}>
                <label className="form-label">Photos</label>
                <div className={styles.scrollimages}>
                  {
                    eventPhotos?.map((item, index) => (
                      <a
                        key={index}
                        href='#'
                        onClick={e => {
                          e.preventDefault();
                          const insert = `
                          <p>&nbsp;</p>
                          <br/>
                          <figure class="image ck-widget ck-widget_with-resizer ck-widget_selected" contenteditable="false">
                            <img src="${generateImageUrl(item.media_id)}" alt="${item.title}">
                            <figcaption class="ck-editor__editable ck-editor__nested-editable image__caption_highlighted" data-placeholder="Enter image caption" contenteditable="true">${item.title}</figcaption>
                              <div class="ck ck-reset_all ck-widget__type-around">
                                <div class="ck ck-widget__type-around__button ck-widget__type-around__button_before" title="Insert paragraph before block">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8">
                                  <path d="M9.055.263v3.972h-6.77M1 4.216l2-2.038m-2 2 2 2.038"></path>
                                </svg>
                              </div>
                              <div class="ck ck-widget__type-around__button ck-widget__type-around__button_after" title="Insert paragraph after block">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8">
                                  <path d="M9.055.263v3.972h-6.77M1 4.216l2-2.038m-2 2 2 2.038"></path>
                                </svg>
                              </div>
                              <div class="ck ck-widget__type-around__fake-caret"></div>
                            </div>
                            <div class="ck ck-reset_all ck-widget__resizer" style="height: 126px; left: 470px; top: 0px; width: 313px;">
                              <div class="ck-widget__resizer__handle ck-widget__resizer__handle-top-left"></div>
                              <div class="ck-widget__resizer__handle ck-widget__resizer__handle-top-right"></div>
                              <div class="ck-widget__resizer__handle ck-widget__resizer__handle-bottom-right"></div>
                              <div class="ck-widget__resizer__handle ck-widget__resizer__handle-bottom-left"></div>
                              <div class="ck ck-size-view" style="display: none;"></div>
                            </div>
                          </figure>
                          <p>&nbsp;</p>
                          <br/>
                          `;
                          let currentData = description;
                          currentData += insert;
                          setDescription(currentData);
                        }}
                        >
                          <img
                            src={generateImageUrl(item.media_id)}
                            alt={item.title}
                          />
                      </a>
                    ))
                  }
                </div>
              </div>
              <RichTextV2 
                title={"Description"} 
                value={description} 
                onChange={ (event: any, editor: any) => {
                  const data = editor.getData();
                  setDescription(data);
                  console.log( { event, editor, data } );
                }} 
                onBlur={ (event: any, editor: any) => {
                  console.log( { event, editor, description } );
                }} 
                onFocus={ (event: any, editor: any) => {
                  console.log( { event, editor, description } );
                }} 
                name={"description"}
                disabled={false} 
                required={false}
                getEditor={(editor: any) => {
                  console.log( 'Editor is ready to use!', editor );
                  setEditor(editor);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportFormScreen;