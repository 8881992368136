import { EventSingleResultInterface } from "../../disaster/interfaces/event-single-result.interface";
import create from "zustand";
import {
  GetTotalEvents,
  ShowEvent,
} from "../../disaster/providers/disaster.provider";
import { toast } from "react-toastify";
import { SelectData } from "../../forms/interfaces/form-input.interface";
import { GetTotalVolunteer } from "../../users/providers/user.provider";
import { GetTotalUserOrganization } from "../../organizations/providers/user-organization.provider";

interface DashboardState {
  disaster?: EventSingleResultInterface;
  loading: boolean;
  selectedDisaster?: SelectData;
  setSelectedDisaster?: (data: SelectData) => void;
  setSelectedDisasterBoot?: () => void;
  setDisaster: (id: string) => void;
  totalEvents?: number;
  totalVolunteer?: number;
  totalOrganizations?: number;
  getTotalEvents?: () => void;
  getTotalVolunteers?: () => void;
  getTotalUserOrganization?: () => void;
}

const useDashboardStore = create<DashboardState>((set, get) => ({
  disaster: undefined,
  loading: false,
  selectedDisaster: undefined,
  eventId: undefined,
  totalEvents: 0,
  totalVolunteer: 0,
  setSelectedDisaster: (data: SelectData) => {
    localStorage.setItem("selectedDisaster", JSON.stringify(data));
    localStorage.setItem("eventId", data.value);
    set({ selectedDisaster: data });
  },
  setSelectedDisasterBoot: () => {
    const data = localStorage.getItem("selectedDisaster");
    set({ selectedDisaster: JSON.parse(data) });
  },
  setDisaster: async (id: string) => {
    set({ loading: true });
    const result = await ShowEvent(id);
    set({ disaster: result, loading: false });
    toast.success("Set disaster success");
  },
  getTotalEvents: async () => {
    const response = await GetTotalEvents();
    if (response.status) {
      set({ totalEvents: response.data as number });
    }
  },
  getTotalVolunteers: async () => {
    const response = await GetTotalVolunteer();
    if (response.status) {
      set({ totalVolunteer: response.data as number });
    }
  },
  getTotalUserOrganization: async () => {
    const response = await GetTotalUserOrganization();
    if (response.status) {
      set({ totalOrganizations: response.data as number });
    }
  },
}));

export { useDashboardStore };
