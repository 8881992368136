import { useEffect, useState } from "react";
import { Modal, Button, Table, ButtonGroup } from "react-bootstrap";
import { dateTimeFormatter } from "../../../helpers/datetime-formatter";
import {
  DateTimePicker,
  Input,
  InputTextArea,
  SelectWithText,
  Toggle,
} from "../../forms/components/input.component";
import { FormAction } from "../../forms/interfaces/form-input.interface";
import PageHeaderComponent from "../../home/components/page-header.component";
import { useCityStore } from "../states/city.state";
import {
  useDisasterFormStore,
  useDisasterStore,
} from "../states/disaster.state";
import { useEventTypeStore } from "../states/event-type.state";
import { useProvinceStore } from "../states/province.state";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { EventData } from "../interfaces/event-result.interface";
import FormsComponent from "../../forms/components/forms.component";

const DisasterScreen = () => {
  const { getEvents, datas, loading, result, deleteEvent } = useDisasterStore();
  const {
    submitDone,
    formLoading,
    forms,
    setForms,
    generateForms,
    setFormSelectData,
    onSubmitForm,
    formParam,
  } = useDisasterFormStore();
  const { eventTypeSelectDatas, getEventTypes } = useEventTypeStore();
  const { provinceSelectDatas, getProvinces, provinceDatas } =
    useProvinceStore();
  const { citySelectDatas, getCitys } = useCityStore();
  const [show, setShow] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [search, setSearch] = useState<string>();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: EventData
  ) => {
    generateForms({
      action: FormAction.UPDATE,
      title: "Event",
      subtitle: "Ubah Bencana",
      id: item.id,
      data: item,
    });
    handleShow();
  };

  const handleView = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: EventData
  ) => {
    generateForms({
      action: FormAction.VIEW,
      title: "Event",
      subtitle: "Lihat Bencana",
      id: item.id,
      data: item,
    });
    handleShow();
  };

  useEffect(() => {
    getEvents({
      size: size,
      page: page,
      name: search,
    });
  }, [page]);

  useEffect(() => {
    if (show) {
      getEventTypes();
      getProvinces();
    }
  }, [show]);

  useEffect(() => {
    if (eventTypeSelectDatas.length > 0) {
      if (forms.length > 0) {
        setFormSelectData(3, eventTypeSelectDatas);
      }
    }
  }, [eventTypeSelectDatas]);

  useEffect(() => {
    if (provinceSelectDatas.length > 0) {
      if (forms.length > 0) {
        setFormSelectData(6, provinceSelectDatas);
      }
    }
  }, [provinceSelectDatas]);

  useEffect(() => {
    console.log("zxczxc", citySelectDatas);
    if (citySelectDatas.length > 0) {
      if (forms.length > 0) {
        setFormSelectData(7, citySelectDatas);
      }
    }
  }, [citySelectDatas]);

  useEffect(() => {
    if (submitDone !== undefined) {
      if (submitDone) {
        handleClose();
        generateForms({
          action: FormAction.CREATE,
          title: "Disaster",
          subtitle: "Tambah Bencana",
        });
        getEvents({
          page: page,
          size: size,
          name: search,
        });
      }
    }
  }, [submitDone]);

  return (
    <>
      <PageHeaderComponent
        title="Bencana"
        subtitle="Daftar Bencana"
        hasButton={true}
        buttonTitle="Tambah"
        onClick={(e) => {
          generateForms({
            action: FormAction.CREATE,
            title: "Disaster",
            subtitle: "Tambah Bencana",
            id: undefined,
            data: undefined,
          });
          handleShow();
        }}
      />
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getEvents({
                    page: page,
                    size: size,
                    name: search,
                  });
                }}
              >
                <Input
                  title={"Search"}
                  name={"search"}
                  type={"text"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button
                  variant="primary"
                  style={{
                    marginLeft: 10,
                    marginTop: 10,
                  }}
                  onClick={(e) => {
                    getEvents({
                      page: page,
                      size: size,
                      name: search,
                    });
                  }}
                >
                  Search
                </Button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="wd-1p">No</th>
                    <th className="wd-15p">Tanggal</th>
                    <th className="wd-15p">Nama</th>
                    <th className="wd-15p">Kategori</th>
                    <th className="wd-15p">Kota/Kabupaten</th>
                    <th className="wd-15p">Status</th>
                    <th className="wd-3p" style={{ width: 50 }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={7}>Loading</td>
                    </tr>
                  )}
                  {datas.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {dateTimeFormatter(
                            Date.parse(item.date_time.toString())
                          )}
                        </td>
                        <td>{item.name}</td>
                        <td>{item.event_type.name}</td>
                        <td>{item.city.name}</td>
                        <td>{item.is_active ? "Aktif" : "Nonaktif"}</td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="primary"
                              onClick={(e) => handleView(e, item)}
                            >
                              Lihat
                            </Button>
                            <Button
                              variant="warning"
                              onClick={(e) => handleEdit(e, item)}
                            >
                              Ubah
                            </Button>
                            <Button
                              variant="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                const response = window.confirm(
                                  "Apakah yakin ingin menghapus data ini ?"
                                );
                                if (response === true) {
                                  deleteEvent(item.id);
                                }
                              }}
                            >
                              Hapus
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="card-footer">
              <ReactPaginate
                previousLabel={"«"}
                nextLabel={"»"}
                breakLabel={"..."}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                pageCount={result?.data?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-sm m-0 float-right"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size={"lg"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitForm();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{formParam?.subtitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormsComponent
              setForms={setForms}
              forms={forms}
              selectWithTextCb={(index, name, value) => {
                if (index === 6) {
                  const province = provinceDatas?.find((el) => el.id === value);
                  if (province) {
                    getCitys(province?.prov_code);
                  }
                }
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => {
                if (!formLoading) handleClose();
              }}
              className={formLoading && "btn-loading"}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className={formLoading && "btn-loading"}
              onClick={(e) => {
                e.preventDefault();
                if (!formLoading) onSubmitForm();
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default DisasterScreen;
