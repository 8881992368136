import { AxiosError } from "axios";
import { ApiAuthorization } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
// import { UserDetailResultInterface } from "../interfaces/user-detail-result.interface";
import { UserDetailSingleResultInterface } from "../interfaces/user-detail-single-result.interface";

export interface UserDetailParam {
  size: number;
  page: number;
}

// export const GetUserDetails = async (param: UserDetailParam): Promise<UserDetailResultInterface> => {
//   try {
//     const result = await ApiAuthorization.get("/userDetails", {
//       params: {
//         size: param.size,
//         page: param.page
//       }
//     });
//     return result.data as UserDetailResultInterface;
//   } catch (e) {
//     const error = e as AxiosError;
//     return error.response?.data as GeneralInterface;
//   }
// };

// export const GetUserDetailsVolunteer = async (param: UserDetailParam): Promise<UserDetailResultInterface> => {
//   try {
//     const result = await ApiAuthorization.get("/user-details", {
//       params: {
//         size: param.size,
//         page: param.page
//       }
//     });
//     return result.data as UserDetailResultInterface;
//   } catch (e) {
//     const error = e as AxiosError;
//     return error.response?.data as GeneralInterface;
//   }
// };

export const ShowUserDetail = async (id: string): Promise<UserDetailSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.get(
      '/user-details/' + id,
    );
    return response.data as UserDetailSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: UserDetailSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitUserDetail = async (form: any): Promise<UserDetailSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.post(
      '/user-details',
      form
    );
    return response.data as UserDetailSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: UserDetailSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateUserDetail = async (id: string, form: any): Promise<UserDetailSingleResultInterface> => {
  try {
    let response = await ApiAuthorization.put(
      '/user-details/' + id,
      form
    );
    return response.data as UserDetailSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: UserDetailSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteUserDetail = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuthorization.delete(
      '/user-details/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}