import { useState, useEffect } from "react";
import { Table, ButtonGroup, Button, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { generateImageUrl } from "../../../helpers/generate-image-url";
import { useDashboardStore } from "../../dashboard/states/dashboard.state";
import FormsComponent from "../../forms/components/forms.component";
import { FormAction } from "../../forms/interfaces/form-input.interface";
import PageHeaderComponent from "../../home/components/page-header.component";
import { useSocketStore } from "../../home/states/socket.state";
import { PotencyPhotoData } from "../interfaces/potency-photo-result.interface";
import {
  usePotencyPhotoFormStore,
  usePotencyPhotoStore,
} from "../states/potency-photo.state";

const PotencyPhotoScreen: React.FC = () => {
  const {
    getPotencyPhotos,
    potencyPhotos,
    potencyPhotoLoading,
    potencyPhotoModel,
    deletePotencyPhoto,
    appendPotencyPhotos,
  } = usePotencyPhotoStore();
  const {
    submitDone,
    formLoading,
    forms,
    setForms,
    generateForms,
    onSubmitForm,
    formParam,
  } = usePotencyPhotoFormStore();
  const { selectedDisaster } = useDashboardStore();
  const [show, setShow] = useState<boolean>(false);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [pageTitle, setPageTitle] = useState<string>("");
  const { socket } = useSocketStore();

  useEffect(() => {
    socket.connect();
    socket.on("receiveAssessmentPhoto", (msg) => {
      console.log("receiveAssessmentPhoto", msg);
      const data: PotencyPhotoData = JSON.parse(msg);
      console.log("receiveAssessmentPhoto", data);
      console.log(
        "receiveAssessmentPhoto",
        localStorage.getItem("potencyId"),
        data.id
      );
      if (
        data.action === "create" &&
        localStorage.getItem("potencyId") === data.id
      ) {
        appendPotencyPhotos(data);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: PotencyPhotoData
  ) => {
    generateForms({
      action: FormAction.UPDATE,
      title: pageTitle,
      subtitle: "Ubah " + pageTitle,
      id: item.id,
      data: item,
    });
    handleShow();
  };

  const handleView = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: PotencyPhotoData
  ) => {
    generateForms({
      action: FormAction.VIEW,
      title: pageTitle,
      subtitle: "Lihat " + pageTitle,
      id: item.id,
      data: item,
    });
    handleShow();
  };

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  useEffect(() => {
    setPageTitle("Foto");
  }, []);

  useEffect(() => {
    if (selectedDisaster !== undefined) {
      getPotencyPhotos({
        size: size,
        page: page,
      });
    }
  }, [selectedDisaster, page]);

  useEffect(() => {
    console.log(forms);
  }, [forms]);

  useEffect(() => {
    if (submitDone !== undefined) {
      if (submitDone) {
        handleClose();
        generateForms({
          action: FormAction.CREATE,
          title: pageTitle,
          subtitle: "Tambah " + pageTitle,
        });
        getPotencyPhotos({
          size: size,
          page: 0,
        });
      }
    }
  }, [submitDone]);

  return (
    <>
      <PageHeaderComponent
        title={pageTitle}
        subtitle={`Daftar ${pageTitle}`}
        hasButton={true}
        buttonTitle="Tambah"
        onClick={() => {
          generateForms({
            action: FormAction.CREATE,
            title: pageTitle,
            subtitle: "Tambah " + pageTitle,
            data: undefined,
            id: undefined,
          });
          handleShow();
        }}
      />

      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="wd-1p">No</th>
                    <th className="wd-15p">Judul</th>
                    <th className="wd-15p">Media</th>
                    <th className="wd-3p" style={{ width: 50 }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {potencyPhotoLoading && (
                    <tr>
                      <td colSpan={7}>Loading</td>
                    </tr>
                  )}
                  {potencyPhotos.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td>
                          <img
                            src={generateImageUrl(item.media_id)}
                            alt={item.title}
                            style={{
                              objectFit: "contain",
                              height: 100,
                              width: 100,
                            }}
                          />
                        </td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="primary"
                              onClick={(e) => handleView(e, item)}
                            >
                              Lihat
                            </Button>
                            <Button
                              variant="warning"
                              onClick={(e) => handleEdit(e, item)}
                            >
                              Ubah
                            </Button>
                            <Button
                              variant="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                const response = window.confirm(
                                  "Apakah yakin ingin menghapus data ini ?"
                                );
                                if (response === true) {
                                  deletePotencyPhoto(item.id, {
                                    page: 0,
                                    size: size,
                                  });
                                }
                              }}
                            >
                              Hapus
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="card-footer">
              <ReactPaginate
                previousLabel={"«"}
                nextLabel={"»"}
                breakLabel={"..."}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                pageCount={potencyPhotoModel?.data?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-sm m-0 float-right"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size={"lg"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitForm();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{formParam?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormsComponent forms={forms} setForms={setForms} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                if (!formLoading) handleClose();
              }}
              className={formLoading && "btn-loading"}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className={formLoading && "btn-loading"}
              onClick={(e) => {
                e.preventDefault();
                if (!formLoading) onSubmitForm();
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default PotencyPhotoScreen;
