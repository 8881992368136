const FooterComponent = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center flex-row-reverse">
          <div className="col-md-12 col-sm-12 mt-3 mt-lg-0 text-center">
            © {new Date().getFullYear()} TERRA Telkomsel
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
