import { useEffect, useState } from "react";
import { Table, ButtonGroup, Button, Modal, Alert } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { dateTimeFormatter } from "../../../helpers/datetime-formatter";
import { useDashboardStore } from "../../dashboard/states/dashboard.state";
import FormsComponent from "../../forms/components/forms.component";
import { FormAction } from "../../forms/interfaces/form-input.interface";
import PageHeaderComponent from "../../home/components/page-header.component";
import { useUserStore } from "../../users/states/user.state";
import { AssignmentData } from "../interfaces/assignment-result.interface";
import {
  useAssignmentFormStore,
  useAssignmentStore,
} from "../states/assignment.state";
import { toast } from "react-toastify";

const AssignmentScreen = () => {
  const {
    getAssignments,
    assignments,
    assignmentLoading,
    assignmentModel,
    deleteAssignment,
  } = useAssignmentStore();
  const {
    submitDone,
    formLoading,
    forms,
    setForms,
    generateForms,
    setFormSelectData,
    onSubmitForm,
    formParam,
  } = useAssignmentFormStore();
  const { getUsersVolunteer, userSelectDatas } = useUserStore();
  const { selectedDisaster } = useDashboardStore();
  const [show, setShow] = useState<boolean>(false);
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    getUsersVolunteer({ page: 0, size: 10, name: undefined });
    setShow(true);
  };

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: AssignmentData
  ) => {
    generateForms({
      action: FormAction.UPDATE,
      title: "Volunteer",
      subtitle: "Ubah Assignment",
      id: item.id,
      data: item,
      eventId: selectedDisaster.value,
    });
    handleShow();
  };

  const handleView = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: AssignmentData
  ) => {
    generateForms({
      action: FormAction.VIEW,
      title: "Assignment",
      subtitle: "Lihat Assignment",
      id: item.id,
      data: item,
      eventId: selectedDisaster.value,
    });
    handleShow();
  };

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  useEffect(() => {
    console.log("asdasd", selectedDisaster);
    if (selectedDisaster !== undefined && selectedDisaster !== null) {
      getAssignments({
        size: size,
        page: page,
        event_id: selectedDisaster?.value,
      });
      setShowAlert(false);
    } else {
      setShowAlert(true);
    }
  }, [selectedDisaster, page]);

  useEffect(() => {
    console.log(forms);
  }, [forms]);

  useEffect(() => {
    if (userSelectDatas.length > 0) {
      setFormSelectData(0, userSelectDatas);
    }
  }, [userSelectDatas]);

  useEffect(() => {
    if (submitDone !== undefined) {
      if (submitDone) {
        handleClose();
        generateForms({
          action: FormAction.CREATE,
          title: "Assignment",
          subtitle: "Tambah Assignment",
          eventId: selectedDisaster.value,
        });
        getAssignments({
          size: size,
          page: 0,
          event_id: selectedDisaster.value,
        });
        getUsersVolunteer({
          size: 100,
          page: 0,
        });
      }
    }
  }, [submitDone]);

  return (
    <>
      <PageHeaderComponent
        title="Assignment"
        subtitle="Daftar Assignment"
        hasButton={true}
        buttonTitle="Tambah"
        onClick={(e) => {
          generateForms({
            action: FormAction.CREATE,
            title: "User",
            subtitle: "Create User",
            data: undefined,
            id: undefined,
            eventId: selectedDisaster.value,
          });
          handleShow();
        }}
      />

      <div className="row">
        {showAlert === true ? (
          <div className="col-md-12 col-lg-12">
            <Alert key={"warning"} variant="warning">
              Mohon pilih bencana terlebih dahulu di{" "}
              <a
                href="/home/dashboard"
                style={{
                  color: "black",
                  fontWeight: 700,
                }}
              >
                Beranda
              </a>
            </Alert>
          </div>
        ) : (
          <></>
        )}

        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="wd-1p">No</th>
                    <th className="wd-15p">Nama</th>
                    <th className="wd-15p">Email</th>
                    <th className="wd-15p">No. HP</th>
                    <th className="wd-15p">Response</th>
                    <th className="wd-15p">Tanggal Mulai</th>
                    <th className="wd-15p">Tanggal Akhir</th>
                    <th className="wd-3p" style={{ width: 50 }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {assignmentLoading && (
                    <tr>
                      <td colSpan={7}>Loading</td>
                    </tr>
                  )}
                  {assignments.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.user?.fullName}</td>
                        <td>{item.user?.email}</td>
                        <td>{item.user?.phone_number}</td>
                        <td>
                          {item.is_reponse === true && item.is_read === true
                            ? "Ya"
                            : item.is_reponse === false && item.is_read === true
                            ? "Tidak"
                            : "Belum merespon"}
                        </td>
                        <td>
                          {dateTimeFormatter(
                            Date.parse(item.start_date.toString())
                          )}
                        </td>
                        <td>
                          {dateTimeFormatter(
                            Date.parse(item.end_date.toString())
                          )}
                        </td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="primary"
                              onClick={(e) => handleView(e, item)}
                            >
                              Lihat
                            </Button>
                            <Button
                              variant="warning"
                              onClick={(e) => handleEdit(e, item)}
                            >
                              Ubah
                            </Button>
                            <Button
                              variant="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                const response = window.confirm(
                                  "Apakah yakin ingin menghapus data ini ?"
                                );
                                if (response === true) {
                                  deleteAssignment(item.id, {
                                    page: 0,
                                    size: size,
                                    event_id: selectedDisaster.value,
                                  });
                                }
                              }}
                            >
                              Hapus
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="card-footer">
              <ReactPaginate
                previousLabel={"«"}
                nextLabel={"»"}
                breakLabel={"..."}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                pageCount={assignmentModel?.data?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageClick}
                containerClassName={"pagination pagination-sm m-0 float-right"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size={"lg"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitForm();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{formParam?.subtitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormsComponent
              setForms={setForms}
              forms={forms}
              selectWithTextInputChangeCb={(index, name, value) => {
                console.log(index, value);
                if (index === 0) {
                  getUsersVolunteer({ page: 0, size: 10, name: value });
                }
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => {
                if (!formLoading) handleClose();
              }}
              className={formLoading && "btn-loading"}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className={formLoading && "btn-loading"}
              onClick={(e) => {
                e.preventDefault();
                if (!formLoading) onSubmitForm();
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AssignmentScreen;
