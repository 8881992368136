import { AxiosError } from "axios";
import { ApiAssignment } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { AssignmentResultInterface } from "../interfaces/assignment-result.interface";
import { AssignmentSingleResultInterface } from "../interfaces/assignment-single-result.interface";

export interface AssignmentParam {
  size: number;
  page: number;
  event_id: string;
}

export const GetAssignments = async (param: AssignmentParam): Promise<AssignmentResultInterface> => {
  try {
    const result = await ApiAssignment.get("/assignments", {
      params: {
        size: param.size,
        page: param.page,
        event_id: param.event_id
      }
    });
    return result.data as AssignmentResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowAssignment = async (id: string): Promise<AssignmentSingleResultInterface> => {
  try {
    let response = await ApiAssignment.get(
      '/assignments/' + id,
    );
    return response.data as AssignmentSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: AssignmentSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitAssignment = async (form: any): Promise<AssignmentSingleResultInterface> => {
  try {
    let response = await ApiAssignment.post(
      '/assignments',
      form
    );
    return response.data as AssignmentSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: AssignmentSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateAssignment = async (id: string, form: any): Promise<AssignmentSingleResultInterface> => {
  try {
    let response = await ApiAssignment.put(
      '/assignments/' + id,
      form
    );
    return response.data as AssignmentSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: AssignmentSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteAssignment = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAssignment.delete(
      '/assignments/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}