import { toast } from "react-toastify";
import create from "zustand";
import { generateFormLogic } from "../../../helpers/generate-form-logic";
import {
  SelectData,
  FormInput,
  FormAction,
  FormProps,
} from "../../forms/interfaces/form-input.interface";
import {
  PotencyPhotoData,
  PotencyPhotoResultInterface,
} from "../interfaces/potency-photo-result.interface";
import { PotencyPhotoSingleResultInterface } from "../interfaces/potency-photo-single-result.interface";
import {
  PotencyPhotoParam,
  GetPotencyPhotos,
  DeletePotencyPhoto,
  ShowPotencyPhoto,
  SubmitPotencyPhoto,
  UpdatePotencyPhoto,
} from "../providers/potency-photo.provider";

interface PotencyPhotoState {
  potencyPhotos: PotencyPhotoData[];
  potencyPhotoModel?: PotencyPhotoResultInterface;
  potencyPhotoLoading: boolean;
  potencyPhotoSelectDatas: SelectData[];
  getPotencyPhotos: (potencyPhotoParam: PotencyPhotoParam) => void;
  deletePotencyPhoto: (
    id: string,
    potencyPhotoParam: PotencyPhotoParam
  ) => void;
  appendPotencyPhotos?: (data: PotencyPhotoData) => void;
}
const usePotencyPhotoStore = create<PotencyPhotoState>((set, get) => ({
  potencyPhotos: [],
  potencyPhotoSelectDatas: [],
  potencyPhotoModel: undefined,
  potencyPhotoLoading: false,
  getPotencyPhotos: async (potencyPhotoParam: PotencyPhotoParam) => {
    set({ potencyPhotoLoading: true });
    const result = await GetPotencyPhotos(potencyPhotoParam);
    set({ potencyPhotoModel: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          let transformsDatas: SelectData[] = [];
          for (const item of result.data.items) {
            transformsDatas.push({
              label: item.content,
              value: item.id,
            });
          }
          set({
            potencyPhotos: result.data.items,
            potencyPhotoSelectDatas: transformsDatas,
          });
        } else {
          set({
            potencyPhotos: [],
            potencyPhotoSelectDatas: [],
          });
        }
      } else {
        set({
          potencyPhotos: [],
          potencyPhotoSelectDatas: [],
        });
      }
    } else {
      set({
        potencyPhotos: [],
        potencyPhotoSelectDatas: [],
      });
    }
    set({ potencyPhotoLoading: false });
  },
  deletePotencyPhoto: async (id: string, param: PotencyPhotoParam) => {
    set({ potencyPhotoLoading: true });
    const result = await DeletePotencyPhoto(id);
    if (result.status) {
      toast.success(result.message);
      get().getPotencyPhotos(param);
    } else {
      toast.error(result.message);
    }
    set({ potencyPhotoLoading: false });
  },
  appendPotencyPhotos: (data: PotencyPhotoData) => {
    // check is data is exist
    const index = get().potencyPhotos.findIndex((el) => el.id === data.id);
    if (index === -1) {
      const datas = [data, ...get().potencyPhotos];
      set({ potencyPhotos: datas });
    }
  },
}));

interface PotencyPhotoFormState {
  forms?: FormInput[];
  setForms?: (index: number, value: any) => void;
  setFormSelectData: (index: number, selectData: SelectData[]) => void;
  loading: boolean;
  formLoading?: boolean;
  submitDone?: boolean;
  data?: PotencyPhotoSingleResultInterface;
  generateForms: (formParam: FormProps) => void;
  onSubmitForm: () => void;
  fetchSingleData: (id: string) => void;
  formParam?: FormProps;
  setFormParam?: (formParam: FormProps) => void;
  reset: () => void;
}

const potencyFormInitialState = {
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
};

const usePotencyPhotoFormStore = create<PotencyPhotoFormState>((set, get) => ({
  loading: false,
  forms: [],
  data: undefined,
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      value: value,
    };
    set({ forms: forms });
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms];
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    };
    set({ forms: forms });
  },
  setFormParam: (formParam: FormProps) => {
    set({ formParam: formParam });
  },
  fetchSingleData: async (id: string) => {
    set({ loading: true });
    if (id !== undefined || id !== null) {
      const response = await ShowPotencyPhoto(id);
      set({ data: response });
    } else {
      set({ data: undefined });
    }
    set({ loading: false });
  },
  generateForms: (formParam: FormProps) => {
    set({ forms: [], formParam: formParam, submitDone: undefined });
    let data: PotencyPhotoData = undefined;
    if (formParam.data !== undefined) {
      data = formParam.data as PotencyPhotoData;
    }
    let forms: FormInput[] = [];

    forms = [
      {
        title: "Media",
        placeholder: "Choose file...",
        type: "file-upload-v2",
        name: "media_id",
        value: data?.media_id,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Title",
        placeholder: "title...",
        type: "text",
        name: "title",
        value: data?.title ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        title: "Content",
        placeholder: "Content...",
        type: "text",
        name: "content",
        value: data?.content ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Latitude",
        placeholder: "Latitude...",
        type: "text",
        name: "lat",
        value: data?.lat ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        title: "Longitude",
        placeholder: "Longitude...",
        type: "text",
        name: "lng",
        value: data?.lng ?? "",
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
    ];
    set({ forms: forms });
  },
  onSubmitForm: async () => {
    // check required field
    let errorExist = false;
    for (const v of get().forms) {
      if (v.required === true && (v.value === undefined || v.value === "")) {
        toast.error(`Please fill ${v.title}`);
        errorExist = true;
      }
    }

    if (errorExist === true) {
      return;
    }

    try {
      set({ formLoading: true, submitDone: false });
      if (get().formParam.action === FormAction.VIEW) {
        set({ formLoading: false, submitDone: false });
        return toast.error("Cant submit form, because its read-only");
      }

      set({ loading: true });

      let formData = {};
      for (const v of get().forms) {
        formData = {
          ...formData,
          [v.name]: generateFormLogic(v.type, v?.value ?? ""),
        };
      }

      let res: PotencyPhotoSingleResultInterface = {};

      if (get().formParam.action === FormAction.CREATE)
        res = await SubmitPotencyPhoto(formData);
      if (get().formParam.action === FormAction.UPDATE)
        res = await UpdatePotencyPhoto(get().formParam.id, formData);

      if (res.status) {
        toast.success(res.message);
        set({ forms: [], submitDone: true });
      } else {
        if (Array.isArray(res.message)) {
          for (const error of res.message) {
            toast.error(error);
          }
        } else {
          toast.error(res.message ?? res.error);
        }
        set({ submitDone: false });
      }
      set({ formLoading: false });
    } catch (e) {
      console.log(e);
      toast.error(e);
      set({ formLoading: false, submitDone: false });
    }
  },
  reset: () => {
    set(potencyFormInitialState);
  },
}));

export { usePotencyPhotoStore, usePotencyPhotoFormStore };
