import { useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import AssignmentScreen from "../../assignments/screens/assignment.screen";
import { useAuthStore } from "../../auth/states/auth.state";
import DashboardScreen from "../../dashboard/screens/dashboard.screen";
import DisasterScreen from "../../disaster/screens/disaster.screen";
import EventGeneralInfoScreen from "../../event-general-infos/screens/event-general-info.screen";
import EventPhotoScreen from "../../event-photos/screens/event-photo.screen";
import EventVideoScreen from "../../event-videos/screens/event-video.screen";
import UserOrganizationScreen from "../../organizations/screens/user-organization.screen";
import ReportLayout from "../../reports/screens/report.screen";
import ResourceScreen from "../../resources/screens/resource.screen";
import RoleScreen from "../../roles/screens/role.screen";
import UserScreen from "../../users/screens/user.screen";
import VolunteerScreen from "../../users/screens/volunteer.screen";
import FooterComponent from "../components/footer.component";
import NavbarComponent from "../components/navbar.component";
import UserDetailFormComponent from "../../users/screens/user-detail-form";
import { useSocketStore } from "../states/socket.state";
import ItemScreen from "../../items/screens/item.screen";
import PotencyPhotoScreen from "../../potency-photos/screens/potency-photo.screen";
import PotencyVideoScreen from "../../potency-videos/screens/potency-video.screen";

const HomeScreen = () => {
  const { logout, meModel } = useAuthStore();

  const [show, setShow] = useState<boolean>(false);
  const [mobileNavbar, setMobileNavbar] = useState<boolean>(false);

  return (
    <div className="page">
      <div className="page-main">
        <div className="header py-1">
          <div className="container">
            <div className="d-flex">
              <a className="header-brand" href="/">
                {/* <img src="/img/logo.png" className="header-brand-img" alt=""> */}
              </a>
              <div className="mt-4 text-white font-weight-bold h3">
                Administrasi TERRA
              </div>
              <div className="d-flex order-lg-2 ml-auto">
                <div className={`dropdown mt-1 ${show && "show"}`}>
                  <a
                    href="#"
                    className={`nav-link pr-0 leading-none ${show && "show"}`}
                    data-toggle="dropdown"
                    aria-expanded={`${show ? true : false}` ?? false}
                    onClick={(e) => {
                      setShow(!show);
                    }}
                  >
                    <span
                      className="avatar avatar-md brround"
                      style={{
                        backgroundImage:
                          "url(/assets/images/no_profile_pict.png)",
                      }}
                    ></span>
                  </a>
                  <div
                    className={`dropdown-menu dropdown-menu-right dropdown-menu-arrow ${
                      show && "show"
                    }`}
                    style={{
                      position: "absolute",
                      transform: "translate3d(-202px, 46px, 0px)",
                      top: "0px",
                      left: "0px",
                      willChange: "transform",
                    }}
                  >
                    <div className="text-center">
                      <a
                        href="/"
                        className="dropdown-item text-center font-weight-sembold user"
                      >
                        {meModel?.data?.fullName}
                      </a>
                      <span className="text-center user-semi-title text-dark">
                        {meModel?.data?.role?.name}
                      </span>
                      <div className="dropdown-divider"></div>
                    </div>
                    <a className="dropdown-item" href="/">
                      <i className="dropdown-icon mdi mdi-account-outline "></i>{" "}
                      Profil
                    </a>
                    <a className="dropdown-item" href="/">
                      <i className="dropdown-icon  mdi mdi-settings"></i>{" "}
                      Pengaturan
                    </a>
                    <a className="dropdown-item" href="/">
                      <i className="dropdown-icon mdi mdi-comment-check-outline"></i>{" "}
                      Pesan
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={(e) => logout()}
                    >
                      <i className="dropdown-icon mdi  mdi-logout-variant"></i>{" "}
                      Keluar
                    </a>
                  </div>
                </div>
              </div>
              <a
                href="#"
                className={`header-toggler d-lg-none ml-3 ml-lg-0 ${
                  mobileNavbar && "collapsed"
                }`}
                data-toggle="collapse"
                data-target="#headerMenuCollapse"
                aria-expanded={`${mobileNavbar ? true : false}` ?? false}
                onClick={(e) => {
                  setMobileNavbar(!mobileNavbar);
                }}
              >
                <span className="header-toggler-icon"></span>
              </a>
            </div>
          </div>
        </div>

        {/* Navbar */}
        <NavbarComponent show={mobileNavbar} />

        <div className="my-3 my-md-5">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </div>
      {/* Footer */}
      <FooterComponent />
    </div>
  );
};

const HomeLayout = () => {
  const { socket } = useSocketStore();

  return (
    <Routes>
      <Route element={<HomeScreen />}>
        <Route path="dashboard" element={<DashboardScreen />} />
        <Route path="disaster" element={<DisasterScreen />} />
        <Route path="role" element={<RoleScreen />} />
        <Route path="user" element={<UserScreen />} />
        <Route path="user-volunteer" element={<VolunteerScreen />} />
        <Route path="user-detail" element={<UserDetailFormComponent />} />
        <Route path="user-organizations" element={<UserOrganizationScreen />} />
        <Route path="assignment" element={<AssignmentScreen />} />
        <Route path="tv" element={<EventGeneralInfoScreen code={10} />} />
        <Route path="general" element={<EventGeneralInfoScreen code={12} />} />
        <Route path="live" element={<EventGeneralInfoScreen code={11} />} />
        <Route path="photo" element={<EventPhotoScreen />} />
        <Route path="video" element={<EventVideoScreen />} />
        <Route path="resource" element={<ResourceScreen />} />
        <Route path="items" element={<ItemScreen />} />
        <Route path="report/*" element={<ReportLayout />} />
        <Route path="potency-photo" element={<PotencyPhotoScreen />} />
        <Route path="potency-video" element={<PotencyVideoScreen />} />
        <Route index element={<DashboardScreen />} />
      </Route>
    </Routes>
  );
};

export default HomeLayout;
