import { useContext, useState, useEffect, useRef } from "react";
import { generateImageUrl } from "../../../helpers/generate-image-url";
import { SingleFileUploadResult } from "../../uploads/interfaces/single-file-upload-result";
import { UploadContextProvider, UploadContext, UploadContextType } from "../../uploads/states/upload-context";

interface FileUploadProps {
  title: string;
  name?: string;
  description?: string;
  multiple: boolean;
  value?: string;
  disabled: boolean;
  onUploadDone?: (file: SingleFileUploadResult) => void;
  isImage?: boolean;
  isVideo?: boolean;
  required?: boolean;
}

export const FileUploadVideoV2: React.FC<FileUploadProps> = ({
  title,
  name,
  description,
  multiple,
  value,
  disabled = false,
  onUploadDone,
  isImage = true,
  isVideo = true,
  required = false,
}) => {
  return (
    <UploadContextProvider>
      <FileUploadVideoState
        title={title}
        name={name}
        description={description}
        multiple={multiple}
        value={value}
        disabled={disabled}
        onUploadDone={onUploadDone}
        isImage={isImage}
        isVideo={isVideo}
        required={required}
      />
    </UploadContextProvider>
  );
}

const FileUploadVideoState: React.FC<FileUploadProps> = ({
  title,
  name,
  description,
  multiple,
  value,
  disabled = false,
  onUploadDone,
  isImage = true,
  isVideo = true,
  required = false,
}) => {
  
  const videoRef = useRef<HTMLVideoElement>(null);
  const { setSingleFileVideo, resultSingleFile, total, loaded } = useContext(UploadContext) as UploadContextType;
  const [width, setWidth] = useState<string>('0%');

  useEffect(() => {
    videoRef.current?.load();
  }, [value]);

  useEffect(() => {
    let t = Math.floor((loaded / total) * 100)
    let s = t.toString() + '%';
    setWidth(s);
  }, [total, loaded]);

  useEffect(() => {
    if (resultSingleFile !== undefined) {
      onUploadDone(resultSingleFile);
    }
  }, [resultSingleFile])

  return (
    <div className="col-sm-12 col-md-12">
      <label className="form-label">
        {title}
        {
          required === true &&
          <span style={{ marginLeft: 5, color: 'red'}}>*</span>
        }
      </label>

      {/* Image File */}
      {
        value !== undefined && value !== '' ?
        <>
          <br/>
          {
            isVideo ?
            <video ref={videoRef} width="320" height="240" controls>
              <source src={generateImageUrl(value)} type="video/mp4"/>
            </video> 
            :
            <>
              <a 
                href={`${process.env.REACT_APP_DOWNLOADS_URL}/${value}`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary">
                Download File
              </a>
              {/* <input  
                id="exampleFile" 
                type="text" 
                className="form-control"
                disabled
                value={`${process.env.REACT_APP_DOWNLOADS_URL}/${value}`}
              /> */}
            </>
          }
        </>
        : <div></div>
      }

      <input 
        name={name} 
        id="exampleFile" 
        type="file" 
        className="form-control"
        disabled={disabled}
        multiple={multiple}
        onChange={(e) => {
          const file = e.target.files[0];
          console.log("dsadsa", file)
          setSingleFileVideo(file);
        }}/>
      <small className="form-text text-muted">
        {description}
      </small>
      <div className="mb-3 progress">
        <div 
          className="progress-bar progress-bar-animated bg-success progress-bar-striped" 
          role="progressbar" 
          aria-valuenow={loaded}
          aria-valuemin={0}
          aria-valuemax={total}
          style={{
            width: width
          }}>
            {
              loaded === 0 && total === 0 
              ? ''
              : loaded === total ? 'Upload done' : `${width} Uploading...`
            }
        </div>
      </div>
    </div>
  );
}

export const FileUploadV2: React.FC<FileUploadProps> = ({
  title,
  name,
  description,
  multiple,
  value,
  disabled = false,
  onUploadDone,
  isImage = true,
  required = false,
}) => {
  return (
    <UploadContextProvider>
      <FileUploadState
        title={title}
        name={name}
        description={description}
        multiple={multiple}
        value={value}
        disabled={disabled}
        onUploadDone={onUploadDone}
        isImage={isImage}
        required={required}
      />
    </UploadContextProvider>
  );
}

const FileUploadState: React.FC<FileUploadProps> = ({
  title,
  name,
  description,
  multiple,
  value,
  disabled = false,
  onUploadDone,
  isImage = true,
  required = false
}) => {

  const { setSingleFile, resultSingleFile, total, loaded } = useContext(UploadContext) as UploadContextType;
  const [width, setWidth] = useState<string>('0%');

  useEffect(() => {
    let t = Math.floor((loaded / total) * 100)
    let s = t.toString() + '%';
    setWidth(s);
  }, [total, loaded]);

  useEffect(() => {
    if (resultSingleFile !== undefined) {
      onUploadDone(resultSingleFile);
    }
  }, [resultSingleFile])

  return (
    <div className="col-sm-12 col-md-12">
      <label className="form-label">
        {title}
        {
          required === true &&
          <span style={{ marginLeft: 5, color: 'red'}}>*</span>
        }
      </label>

      {/* Image File */}
      {
        value !== undefined && value !== '' ?
        <>
          <br/>
          {
            isImage ?
            <img
              src={generateImageUrl(value)}
              alt={'my_avatar'}
              style={{
                width: '100%',
                height: 250,
                borderRadius: 10,
                marginBottom: 10,
                objectFit: 'contain',
                boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
              }}
            /> :
            <>
              <a 
                href={`${process.env.REACT_APP_DOWNLOADS_URL}/${value}`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary">
                Download File
              </a>
              {/* <input  
                id="exampleFile" 
                type="text" 
                className="form-control"
                disabled
                value={`${process.env.REACT_APP_DOWNLOADS_URL}/${value}`}
              /> */}
            </>
          }
        </>
        : <div></div>
      }

      <input 
        name={name} 
        id="exampleFile" 
        type="file" 
        className="form-control"
        disabled={disabled}
        multiple={multiple}
        onChange={(e) => {
          const file = e.target.files[0];
          setSingleFile(file);
        }}/>
      <small className="form-text text-muted">
        {description}
      </small>
      <div className="mb-3 progress">
        <div 
          className="progress-bar progress-bar-animated bg-success progress-bar-striped" 
          role="progressbar" 
          aria-valuenow={loaded}
          aria-valuemin={0}
          aria-valuemax={total}
          style={{
            width: width
          }}>
            {
              loaded === 0 && total === 0 
              ? ''
              : loaded === total ? 'Upload done' : `${width} Uploading...`
            }
        </div>
      </div>
    </div>
  );
}