import moment from "moment";
import { FormInput } from "../modules/forms/interfaces/form-input.interface";

export const generateFormLogic = (type: FormInput["type"], value: any) => {
  switch (type) {
    case "select-with-text":
      return value["value"];
    case "date":
      return moment(value).format("YYYY-MM-DD");
    case "datetime":
      return moment(value).format("YYYY-MM-DDTHH:mm:ss.sssZ");
    // return moment(value).format("YYYY-MM-DD H:mm:ss");
    case "number":
      return +value;
    default:
      return value;
  }
};
