import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { InfoSourceResultInterface } from "../interfaces/info-source-result.interface";
import { InfoSourceSingleResultInterface } from "../interfaces/info-source-single-result.interface";

export interface InfoSourceParam {
  size: number;
  page: number;
}

export const GetInfoSources = async (param: InfoSourceParam): Promise<InfoSourceResultInterface> => {
  try {
    const result = await ApiDisaster.get("/info-sources", {
      params: {
        size: param.size,
        page: param.page,
      }
    });
    return result.data as InfoSourceResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowInfoSource = async (id: string): Promise<InfoSourceSingleResultInterface> => {
  try {
    let response = await ApiDisaster.get(
      '/info-sources/' + id,
    );
    return response.data as InfoSourceSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: InfoSourceSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitInfoSource = async (form: any): Promise<InfoSourceSingleResultInterface> => {
  try {
    let response = await ApiDisaster.post(
      '/info-sources',
      form
    );
    return response.data as InfoSourceSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: InfoSourceSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateInfoSource = async (id: string, form: any): Promise<InfoSourceSingleResultInterface> => {
  try {
    let response = await ApiDisaster.put(
      '/info-sources/' + id,
      form
    );
    return response.data as InfoSourceSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: InfoSourceSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteInfoSource = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.delete(
      '/info-sources/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}