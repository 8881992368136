import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { EventResultInterface } from "../interfaces/event-result.interface";
import { EventSingleResultInterface } from "../interfaces/event-single-result.interface";

export interface GetEventParam {
  size: number;
  page: number;
  name?: string;
}

export const GetEvents = async (
  param?: GetEventParam
): Promise<EventResultInterface> => {
  try {
    const result = await ApiDisaster.get("/events", {
      params: {
        size: param.size ?? 10,
        page: param.page ?? 0,
        name: param.name ?? "",
      },
    });
    return result.data as EventResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const ShowEvent = async (
  id: string
): Promise<EventSingleResultInterface> => {
  try {
    let response = await ApiDisaster.get("/events/" + id);
    return response.data as EventSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventSingleResultInterface = a.response?.data;
    return response;
  }
};

export const SubmitEvent = async (
  form: any
): Promise<EventSingleResultInterface> => {
  try {
    let response = await ApiDisaster.post("/events", form);
    return response.data as EventSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventSingleResultInterface = a.response?.data;
    return response;
  }
};

export const UpdateEvent = async (
  id: string,
  form: any
): Promise<EventSingleResultInterface> => {
  try {
    let response = await ApiDisaster.put("/events/" + id, form);
    return response.data as EventSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: EventSingleResultInterface = a.response?.data;
    return response;
  }
};

export const DeleteEvent = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.delete("/events/" + id);
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};

export const GetTotalEvents = async (): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster.get("/total-events");
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};
