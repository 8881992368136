import { createRef, useEffect } from "react";
import { useDisasterStore } from "../../disaster/states/disaster.state";
import { useEventPhotoStore } from "../../event-photos/states/event-photo.state";
import PageHeaderComponent from "../../home/components/page-header.component";
import { useUserStore } from "../../users/states/user.state";
import { useReportStore } from "../states/report.state";
import { jsPDF } from 'jspdf';
import './report-pdf.module.css';
// import { css } from '@emotion/react'

const ReportPdfScreen = () => {

  const { selectedReport } = useReportStore();
  const { getEvent, eventData } = useDisasterStore();
  const { getUser, userData } = useUserStore();
  const { getEventPhotos, eventPhotos } = useEventPhotoStore();
  const ref = createRef<HTMLDivElement>();
  
  useEffect(() => {
    if (selectedReport !== undefined) {
      getEvent(selectedReport?.event_id);
      getUser(selectedReport?.created_by);
      getEventPhotos({
        size: 10,
        page: 0,
        event_id: selectedReport?.event_id
      })
    }
  }, [selectedReport])

  const generatePdf = async () => {
    const report = new jsPDF({
      unit: 'pt',
      format: 'a4',
    });
    // const data = await html2canvas(ref.current);
    // const img = data.toDataURL("image/png");  
    // const imgProperties = report.getImageProperties(img);
    // const pdfWidth = report.internal.pageSize.getWidth();
    // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    // report.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    // report.save("shipping_label.pdf");
    report.html(ref.current, {
      async callback(report) {
        // save the document as a PDF with name of Memes
        const dateTime = Date.now();
        report.save(`report_${eventData?.name}_${dateTime}`);
      },
      margin: [0, 0, 20, 0],
      autoPaging: 'text'
    });
  }

  return (
    <>
      <PageHeaderComponent
        title="Report PDF"
        subtitle="Export PDF"
        hasButton={true}
        buttonTitle="Export PDF"
        onClick={(e) => generatePdf()}
      />
      <div 
        id="printpdf"
        ref={ref}
        className="row"
        style={{
          width: "596px",
          height: "100%"
        }}>
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div
                className="ck-content"
                dangerouslySetInnerHTML={{
                  __html: selectedReport?.description
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // return (
  //   <>
  //     <PageHeaderComponent
        // title="Report PDF"
        // subtitle="Export PDF"
        // hasButton={true}
        // buttonTitle="Export PDF"
        // onClick={(e) => generatePdf()}
  //     />
  //     <div 
  //       id="printpdf"
  //       ref={ref}
  //       className="row"
        // style={{
        //   width: "596px",
        //   height: "100%"
        // }}>
  //       <div 
  //         className="col-md-12 col-lg-12"
  //         style={{
  //           width: "596px",
  //           height: "100%"
  //         }}>
  //         <div 
  //           className="card"
  //           style={{
  //             width: "596px",
  //             height: "100%"
  //           }}>
  //           <div 
  //             className="card-body"
  //             style={{
  //               width: "596px",
  //               height: "100%"
  //             }}>
  //             <Row>
  //               <Col
  //                 lg={6}
  //                 style={{
  //                   display: 'flex',
  //                   flex: 1,
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'center'
  //                 }}>
  //                 <img
  //                   src="https://storage.app-terra.net/storage/1642870719283--image.png"
  //                   width={150}
  //                   style={{
  //                     objectFit: 'contain'
  //                   }}
  //                   alt={'Telkomsel Logo'}
  //                 />
  //               </Col>
  //               <Col
  //                 lg={6}
  //                 style={{
  //                   display: 'flex',
  //                   flex: 1,
  //                   justifyContent: 'flex-end',
  //                   alignItems: 'center'
  //                 }}>
  //                 <img
  //                   src="https://storage.app-terra.net/storage/1642870719239--image.png"
  //                   width={150}
  //                   style={{
  //                     objectFit: 'contain',
  //                   }}
  //                   alt={'Telkomsel Logo'}
  //                 />
  //               </Col>
  //               <Col
  //                 lg={12}>
  //                 <Table striped bordered>
  //                   <tbody>
  //                     <tr>
  //                       <th style={{ width: 250 }}>Nama Kejadian</th>
  //                       <td>{eventData?.name}</td>
  //                     </tr>
  //                     <tr>
  //                       <th style={{ width: 250 }}>Kronologis / Latar Belakang Kejadian</th>
  //                       <td>{selectedReport?.description}</td>
  //                     </tr>
  //                     <tr>
  //                       <th style={{ width: 250 }}>Lokasi</th>
  //                       <td>{eventData?.city?.name}, {eventData?.province?.name}</td>
  //                     </tr>
  //                     <tr>
  //                       <th style={{ width: 250 }}>Korban</th>
  //                       <td>{selectedReport?.fatalities}</td>
  //                     </tr>
  //                     <tr>
  //                       <th style={{ width: 250 }}>Kerugian Materiil</th>
  //                       <td>{selectedReport?.material_loss}</td>
  //                     </tr>
  //                     <tr>
  //                       <th style={{ width: 250 }}>Kondisi Sinyal Telkomsel</th>
  //                       <td>{selectedReport?.signal_condition}</td>
  //                     </tr>
  //                     <tr>
  //                       <th style={{ width: 250 }}>Penanganan</th>
  //                       <td>{selectedReport?.response_handling}</td>
  //                     </tr>
  //                     <tr>
  //                       <th style={{ width: 250 }}>Pembuat Laporan</th>
  //                       <td>{userData?.fullName}</td>
  //                     </tr>
  //                     <tr>
  //                       <th style={{ width: 250 }}>Tanggal / Jam</th>
  //                       <td>{timeDateFormatter(Date.parse(selectedReport?.createdAt?.toString()))}</td>
  //                     </tr>
  //                   </tbody>
  //                 </Table>
  //               </Col>
  //               <Col lg={12}>
  //                 <span style={{
  //                   fontWeight: 700,
  //                   fontSize: 18
  //                 }}>
  //                   Photos
  //                 </span>
  //               </Col>
  //             </Row>

  //             <Row>
  //               {
  //                 eventPhotos?.map((item, index) => (
  //                   <Col 
  //                     lg={4}>
  //                       <Card
  //                         style={{
  //                           display: 'flex',
  //                           justifyContent: 'center',
  //                           alignItems: 'center',
  //                           flexDirection: 'column'
  //                         }}>
  //                         <Card.Img
  //                           src={generateImageUrl(item.media_id)}
  //                           alt={item.title}
  //                           style={{
  //                             objectFit: 'contain',
  //                             height: 150
  //                           }}
  //                           variant={'top'}
  //                         />
  //                         <Card.Title>{item.title}</Card.Title>
  //                       </Card>
  //                   </Col>
  //                 ))
  //               } 
  //             </Row>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
}

export default ReportPdfScreen;